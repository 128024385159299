import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import $ from 'jquery';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FadeIn from "react-lazyload-fadein";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Duration from '../../component/duration'




class VideoCard extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Link className="box item_box" to={this.props.videoLink} onMouseEnter={this.changeSpeed} onMouseOut={this.pauseSpeed}>
          <div className="img load_place_horiz">
            {/* <FadeIn>
              {onload => (
                <img
                className={this.props.states.imageType == 'v' ? 'load_place_vert_more transition' : 'load_place_horiz_cat transition'}
                  src={this.props.imgSrc + '/width=300/height=168/quality=100'}
                  onLoad={onload}
                />
              )}
            </FadeIn> */}

            {
              (this.props.response.ispremium === 1) ? <span className="premium_tag"><img src="images/premimum.png" /></span> : ""

            }

            <LazyLoadImage
              className={this.props.states.imageType === 'v' ? 'load_place_vert_more transition' : 'load_place_horiz_cat transition'}
              effect="blur"
              src={this.props.imgSrc + '/width=300/height=168/quality=100'}
              placeholderSrc="images/hor_placeholder.png"
              alt="image"
            />

            <span className="play_arrow transition">
              <PlayCircleOutlineIcon className="text-white" />
            </span>
            <div className={`desc d-flex align-items-center flex-column transition pl-2 ${this.props.data_thumb}`}>
              <div className="title_wrap  d-flex align-items-center w-100">
                <div className="icon">
                  <PlayArrowIcon />
                </div>
                <div className="info ml-1 d-flex align-items-center pr-3">
                  <span className="title mr-2 font-w-400">{this.props.title.toLowerCase()}</span>
                  {
                    (this.props.duration) ? <p className="duration ml-auto"><Duration sec1={this.props.duration} /></p> : ""
                  }

                </div>
              </div>
            </div>
            <div className="desc d-flex align-items-center flex-column overlay transition">

             {
               this.props.continue_watching &&     
               <div className="video_played_wrapper">
               <span className="title d-block text-white mb-2">{this.props.title.toLowerCase()}</span>
               <div className="video_played"><span style={{width:`${this.props.played}%`}}></span></div>
             </div>
             }     
          

              <div className={`title_wrap  d-flex align-items-center w-100 ${(this.props.continue_watching==true)? 'hide':''} `}>
                <div className="icon">
                  <PlayArrowIcon />
                </div>
                <div className="info  flex-column">
                  <marquee direction="left" scrollamount="5" className="d-block marquee">
                    <span className="title d-block">{this.props.title.toLowerCase()}</span>
                  </marquee>
                  {/* <div class="marquee-parent">
                    <div class="marquee-child">
                    <span className="title d-inline-block mr-5">{this.props.title}</span>
                    <span className="title d-inline-block mr-5">{this.props.title}</span>
                    <span className="title d-inline-block mr-5">{this.props.title}</span>
                    <span className="title d-inline-block mr-5">{this.props.title}</span>
                    <span className="title d-inline-block mr-5">{this.props.title}</span>
                    <span className="title d-inline-block mr-5">{this.props.title}</span>
                    </div>
                  </div> */}
                  <span className="title d-none">{this.props.title.toLowerCase()}</span>
                  {/* <div className="det"></div> */}
                </div>
              </div>
            </div>
          </div>
        </Link>
        {this.props.key1 !== "History" && (
          <DeleteOutlineIcon dataid={this.props.response.entryid}
            className="removeFromWatchList transition"
            onClick={() =>
              this.props.removeFromList("delete", this.props.response.entryid, this.props.response.name)
            }
            title="delete"
          />
        )}
      </React.Fragment>
    );
  }
}

export default VideoCard;

