import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { MENU_DATA, PARTNER_ID, COUNTRYCODE, USERID, LOGOUT, UUID } from '../../url';
import Search from '../../container/search/search';
import Authenticator from '../../container/Authentication/Authentication';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import { ToastContainer, toast, cssTransition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Icon from '@material-ui/core/Icon';

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 750,
});
class Header extends React.Component {
  state = {
    menuItems: [], keyword: '', user_name: '', leftMenuItems: [], resMenuItems: []
  }

  componentDidMount() {
    this.getData();
    this.getLeftMenuData();
  }

  getData = async () => {
    var formData = new FormData();
    formData.append('menu_type', 'h');
    //formData.append('countrycode', COUNTRYCODE);
    formData.append('partnerid', PARTNER_ID);
    await axios.post(MENU_DATA, formData, {
       headers: { 
         'token': localStorage.getItem('token')
        } 
    }).then((response) => {
        if (response.data.status === 'Success') {
          var result = response.data;
          let logo_data = response.data.logo_data
          localStorage.setItem('logo_data', JSON.stringify(logo_data));
          this.setState({ menuItems: result.data })
        }
        else {

        }
    }).catch((error) => {
      /**
      *If the response status code is 409 - Conflict, then we already have
      **/
     //alert(typeof error.response.status);
      if (error.response.status === 401) {
        //let msg = "Email or Password combination is incorrect";
        let msg = error.response['data']['data'];
        $('#snackbar .msg').html(msg);
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          localStorage.clear();
          window.location.href = "/"
        }, 2000);
      } else {
        console.log(error.message);
      }
    })
  }

  getLeftMenuData = async () => {
    var formData = new FormData();
    formData.append('menu_type', 'l');
    //formData.append('countrycode', COUNTRYCODE);
    formData.append('partnerid', PARTNER_ID);
    let response = await axios.post(MENU_DATA, formData, { headers: { 'token': localStorage.getItem('token') } })

    if (response.data.status === 'Success') {
      var resultleftmenu = response.data;
      this.setState({ leftMenuItems: resultleftmenu.data })
    }
    else {
      this.setState({ leftMenuItems: [] })
    }
  }

  logOut = async () => {
    let formData = new FormData();
    formData.append('uuid', UUID);
    let response = await axios.post(LOGOUT, formData ,{
      headers: { token: localStorage.getItem("token") }
    }).catch((error) => {
      console.log(error);
    });

    if (response.data.status === 'Success') {
      localStorage.clear();
      $('.dropdown-menu').removeClass('show');
      $('#snackbar .msg').html('Logged Out Successfully');
      $('#snackbar').addClass('show');
      setTimeout(function () {
        $('#snackbar').removeClass('show');
      }, 3000);
      this.props.history.push('/')
    }
  }

  openSearch = () => {
    $('.header_search-panel').fadeIn(200);
    document.querySelector('#keyword').focus();
  }

  render() {
    const route_name = this.props.location.pathname.split('/')[1]
    const { menuItems, leftMenuItems } = this.state;

    //header menu start
    const MenuItems = menuItems.map((result, key) => {
      let menuName = result.cat_name;
      //let menuUrl = (result.cat_name === "HOME") ? '' : `${(result.cat_name.replace(/\s/g, '')).toLowerCase()}`
      let menuUrl = (result.cat_name === "HOME") ? '' : `${(result.cat_name.replace(/\s/g, '')).toLowerCase()}/${btoa(result.cat_id)}`

      return (
        <li key={key}
          className={`list-inline-item  ${(route_name.length === 0 && (result.cat_name === 'HOME')) ?
            'active' : result.cat_name.replace(/\s/g, '').toLowerCase() == route_name
              ? 'active' : ''}`}>
          {(result.cat_name === "Downloads") || (result.cat_name === "More") ? ""
            : <Link to={`/${menuUrl}`}
              className="transition" >
              <Button style={{ borderRadius: '0px' }}>{menuName.charAt(0).toUpperCase() + menuName.slice(1).toLowerCase()}
              </Button>
            </Link>
          }
        </li >);
    })

    //left menu start
    const leftMenu = leftMenuItems.map((result, key) => {
      let leftmenuName = result.cat_name;
      let leftmenuicon = result.menu_icon;
      let leftmenuUrl = `${(result.cat_name.replace(/\s/g, '')).toLowerCase()}`
      return (
        <>
          {
            <Link to={`/${leftmenuUrl}`}>
              <Button>
                <img src={leftmenuicon} className="mr-2" style={{ width: '20px' }} />
                {leftmenuName.charAt(0).toUpperCase() + leftmenuName.slice(1).toLowerCase()}
              </Button>
            </Link>
          }
        </>
      );
    })


    const resNavItems = leftMenu.map((item, index) => {
      return (
        <li className="list-inline-item">
          {item}
        </li>
      );
    })

    return (
      <>
        <Search {...this.props} />
        <header className="d-flex align-items-center transition">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1 d-flex align-items-center pl-0 part1">
                <Link to="/"><img src="images/logo_white.png" alt="" className="logo" /></Link>
              </div>

              <div className="col-md-8 d-flex align-items-center pr-0 part2">
                <div className="nav_overlay"></div>
                <nav className="transition">
                  <div className="d-none res-d-flex align-items-center p-3 border-bottom info">
                    <span className="rounded-circle d-flex align-items-center justify-content-center text-white font-14 font-w-700 text-uppercase user_name_"></span>

                    <span className="font-14 text-white ml-3 font-w-600" style={{ lineHeight: '17px' }}>{localStorage.getItem('user_name')}
                      <div className="clearfix"></div>
                      <span id="user_email" className="font-12 font-w-400">{localStorage.getItem('user_name')}</span>
                    </span>
                  </div>
                  <ul className="list list-inline mb-0">
                    <div className="res-hide">
                      {MenuItems}
                    </div>
                    <div className="d-none res-show">
                      <li className="list-inline-item">
                        <Link className="transition" to="/myprofile">
                          <Button style={{ borderRadius: '0px' }}>
                            <PermIdentityIcon className="mr-2" style={{ 'color': '#d5b07a', 'opacity': '0.7' }} />
                            My Profile
                          </Button>
                        </Link>
                      </li >
                      <li className="list-inline-item">
                        <Link className="transition" to="/userhistory">
                          <Button style={{ borderRadius: '0px' }}>
                            <VideoLibraryIcon className="mr-2" style={{ 'color': '#d5b07a', 'opacity': '0.7' }} />
                            History
                          </Button>
                        </Link>
                      </li >
                      {resNavItems}
                      <li className="list-inline-item">
                        <a className="transition" onClick={() => this.logOut()}>
                          <Button>
                            <Icon className="fa fa-sign-out mr-2" style={{ 'color': '#d5b07a', 'opacity': '0.7' }} /> Logout
                          </Button>
                        </a>
                      </li>
                    </div>
                  </ul>
                </nav>
              </div>

              <div className="col-md-3 d-flex align-items-center pr-0  pl-0 justify-content-end part3">
                <Button className="text-white open-search mr-3" onClick={this.openSearch}><SearchIcon className="text-white" /></Button>
                <Link to="/subscription">
                  <Button className="btn btn-border subscribe_tab d-flex align-items-center justify-content-center">Subscribe
                  <ArrowForwardIosIcon className="ml-1" /></Button>
                </Link>
                {localStorage.getItem('token') !== null &&
                  <>
                    <div className="user_drop  ml-4 d-none res-show">
                      <Button variant="contained" color="secondary" className="user_toggle user_name_"></Button>
                    </div>

                    <div className="user_drop dropdown ml-4 res-hide">
                      <Button variant="contained" color="secondary" className="user_toggle dropdown-toggle user_name_" data-toggle="dropdown text-uppercase"></Button>
                      <div className="dropdown-menu dropdown-menu-right user_dropdown">
                        <div className="d-flex align-items-center p-3 border-bottom info">
                          <span className="rounded-circle d-flex align-items-center justify-content-center text-white font-14 font-w-700 text-uppercase user_name_"></span>
                          <span className="font-14 text-white ml-3 font-w-600" style={{ lineHeight: '17px' }}>{localStorage.getItem('user_name')}
                            <div className="clearfix"></div>
                            <span id="user_email" className="font-w-400 font-12">{localStorage.getItem('user_email') !== null && localStorage.getItem('user_email') !== undefined && localStorage.getItem('user_email') !== 'null' && localStorage.getItem('user_email') !== 'undefined' ? localStorage.getItem('user_email') : localStorage.getItem('user_mobile') }</span>
                          </span>
                        </div>

                        <Link to="/myprofile">
                          <Button variant="contained">
                            <PermIdentityIcon className="mr-2" style={{ 'color': '#d5b07a', 'opacity': '0.7' }} /> My Profile
                           </Button>
                        </Link>

                        <Link to="/userhistory">
                          <Button variant="contained">
                            <VideoLibraryIcon className="mr-2" style={{ 'color': '#d5b07a', 'opacity': '0.7' }} /> History
                           </Button>
                        </Link>
                        

                        {leftMenu}

                        <a onClick={() => this.logOut()}>
                          <Button variant="contained">
                            <Icon className="fa fa-sign-out mr-2" style={{ 'color': '#d5b07a', 'opacity': '0.7' }} /> Logout
                          </Button>
                        </a>
                      </div>
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </header>

        <div className="d-none res_nav">
          <ul className="list list-inline mb-0">
            {MenuItems}
          </ul>
        </div>
        <div className="after_header"></div>
      </>
    );
  }
}
export default Authenticator(Header);
