import React from 'react';
import Login from "../Login/login.js"
import PAYMENTREDIRECT from '../../PaymentRedirect';

// const Auth=(OldComponent)=>{
//   class NewComponent extends React.Component{
//     render()
//     {
//       const path=this.props.location.pathname.split('/');
//       //console.log(path[1]);
//       if(localStorage.getItem('token')!==null && path[1]!='PaymentRedirect')
//       {
//       return(<OldComponent {...this.props}/>)
//       }
//       if(path[1]==='PaymentRedirect')
//       {
//         return(<PAYMENTREDIRECT orderid={path[2]} {...this.props}/>)
//       }
      
//       else {
//         //return(<PaymentRedirect  {...this.props}/>)
//         return(<Login  {...this.props}/>)
//       }
//     }
//   }
//   return NewComponent;
// }

const Auth = (OldComponent) => {
  class NewComponent extends React.Component {
    render() {
      const path = this.props.location.pathname.split('/');
      //console.log("Auth=" + localStorage.getItem('token') + "UserID=" + localStorage.getItem('userid'));
      if (localStorage.getItem('token') !== null && path[1] != 'PaymentRedirect') {
        return (<OldComponent {...this.props} />)
      }
      if (path[1] === 'PaymentRedirect') {
        return (<PAYMENTREDIRECT orderid={path[2]} {...this.props} />)
      }

      else {
        //return(<PaymentRedirect  {...this.props}/>)
        //return (<Login  {...this.props} />)
        return (<Login  {...this.props} />)
        //return (<OldComponent {...this.props} />)
      }
    }
  }
  return NewComponent;
}

export default Auth;
