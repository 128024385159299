import React from 'react';
import Loadable from 'react-loadable';
function Loading() {
  return <div></div>;
}
const HOME = Loadable({
  loader: () => import('./container/Home/home.js'),
  loading: Loading,
});

const EXPLORE = Loadable({
  loader: () => import('./container/Explore/explore.js'),
  loading: Loading,
});
const QURAN = Loadable({
  loader: () => import('./container/Quran/quran.js'),
  loading: Loading,
});
const QURANPDF = Loadable({
  loader: () => import('./container/Quranpdf/Quranpdf.js'),
  loading: Loading,
});
const MERCYVIDEO = Loadable({
  loader: () => import('./container/Mercyvideo/mercyvideo.js'),
  loading: Loading,
});
const KIDS = Loadable({
  loader: () => import('./container/Kids/kids.js'),
  loading: Loading,
});
const LIVETV = Loadable({
  loader: () => import('./container/Livetv/livetv.js'),
  loading: Loading,
});
const CATEGORY_DATA = Loadable({
  loader: () => import('./container/Category/category_list.js'),
  loading: Loading,
});
const SERIESSUB_DATA = Loadable({
  loader: () => import('./container/SeriesSubData/seriessubdata.js'),
  loading: Loading,
});
const VIDEO_INFO = Loadable({
  loader: () => import('./container/video_info/video_info.js'),
  loading: Loading,
});
const NOT_FOUND = Loadable({
  loader: () => import('./component/not_found/not_found.js'),
  loading: Loading,
});
const SEARCH_PAGE = Loadable({
  loader: () => import('./container/search_result/search_result.js'),
  loading: Loading,
});
const COLLECTION_DATA = Loadable({
  loader: () => import('./component/Tabs/tabs.js'),
  loading: Loading,
});
const VIEW_MORE = Loadable({
  loader: () => import('./container/ViewMore/ViewMore.js'),
  loading: Loading,
});
const VIEW_MORESERIES = Loadable({
  loader: () => import('./container/ViewMore/ViewMoreSeries.js'),
  loading: Loading,
});
const PLAYLIST_DATA = Loadable({
  loader: () => import('./container/Playlist/playlist.js'),
  loading: Loading,
});
const PLAYLIST_VIDEOS = Loadable({
  loader: () => import('./container/Playlist/playlist_video.js'),
  loading: Loading,
});
const USER_PROFILE = Loadable({
  loader: () => import('./container/profile/profile.js'),
  loading: Loading,
});
const SEASON_EPISODES = Loadable({
  loader: () => import('./container/seasons/seasons.js'),
  loading: Loading,
});
const TICKET = Loadable({
  loader: () => import('./container/ticket/ticket.js'),
  loading: Loading,
});
const TRANSCATION = Loadable({
  loader: () => import('./container/transaction/transaction.js'),
  loading: Loading,
});
const CONTACTUS = Loadable({
  loader: () => import('./container/contactus/contactus.js'),
  loading: Loading,
});
const PAYMENT = Loadable({
  loader: () => import('./container/payment/subscription.js'),
  loading: Loading,
});
const PAYMENT_RESPONSE = Loadable({
  loader: () => import('./container/payment/paymentResponse.js'),
  loading: Loading,
});
// const PAYMENT_FAIL = Loadable({
//   loader: () => import('./container/payment/failure.js'),
//   loading: Loading,
// });
const VIEWMOREPASS = Loadable({
  loader: () => import('./container/ViewMore/ViewMorePass.js'),
  loading: Loading,
});
/*const PAYMENTREDIRECT = Loadable({
  loader: () => import('./PaymentRedirect.js'),
  loading: Loading,
});*/
const SUBSCRIBE = Loadable({
  loader: () => import('./container/More/subscribe.js'),
  loading: Loading,
});
const OPPORTUNITIES = Loadable({
  loader: () => import('./container/More/foropportunities.js'),
  loading: Loading,
});
const APPSETTING = Loadable({
  loader: () => import('./container/More/appsetting.js'),
  loading: Loading,
});
const ADVERTISING = Loadable({
  loader: () => import('./container/More/foradvertising.js'),
  loading: Loading,
});
const YOURACCOUNT = Loadable({
  loader: () => import('./container/More/youraccount.js'),
  loading: Loading,
});
const PRIVACYPOLICY = Loadable({
  loader: () => import('./container/pages/privacypolicy.js'),
  loading: Loading,
});
const FAQ = Loadable({
  loader: () => import('./container/pages/faq.js'),
  loading: Loading,
});
const TERMCONDITION = Loadable({
  loader: () => import('./container/pages/termcondition.js'),
  loading: Loading,
});
const REFUNDPOLICY = Loadable({
  loader: () => import('./container/pages/refundpolicy.js'),
  loading: Loading,
});
const MYWATCHLIST = Loadable({
  loader: () => import('./container/mylist/mylist.js'),
  loading: Loading,
});
const MYHISTORY = Loadable({
  loader: () => import('./container/History/history.js'),
  loading: Loading,
});
const SUPPORT = Loadable({
  loader: () => import('./container/More/support_help.js'),
  loading: Loading,
});
const AGENTCOMMISSION = Loadable({
  loader: () => import('./container/More/agentcommission.js'),
  loading: Loading,
});
const ABOUTUS = Loadable({
  loader: () => import('./container/pages/aboutus.js'),
  loading: Loading,
});
// const EmailModal = Loadable({
//   loader: () => import('./container/Modal/modalshow.js'),
//   loading: Loading,
// });
//https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: HOME },
  //  { path:'/home/:id',exact:true,name: 'Home',  component: HOME },
  { path: '/support/help', exact: true, name: 'support/help', component: SUPPORT },
  //{ path: '/:menu_name/:id', exact: true, name: 'Cat_Page', component: VIDEO_DATA },
  { path: '/cat/:menu_name/:cat_name/:id', exact: true, name: 'Cat_Page', component: CATEGORY_DATA },
  { path: '/cat/series/season/:cat_name/:id', exact: true, name: 'Season', component: SERIESSUB_DATA },
  { path: '/video/:video_name/:video_id', exact: true, name: 'Video', component: VIDEO_INFO },
  { path: '/mercytv_search/search_keyword=/Mercytv@523/:word', exact: true, name: 'Search', component: SEARCH_PAGE },
  { path: '/:menu_name/collectionh/:cat_name/:catId', exact: true, name: 'Collection', component: VIEW_MORE },
  { path: '/:menu_name/collectionseriesh/:cat_name/:catId', exact: true, name: 'Collectionseries', component: VIEW_MORESERIES },
  { path: '/:menu_name/collectionseriesv/:cat_name/:catId', exact: true, name: 'Collectionseries', component: VIEW_MORESERIES },
  { path: '/:menu_name/collectionv/:cat_name/:catId', exact: true, name: 'Collection', component: VIEW_MORE },
  { path: '/:menu_name/collection/season/:cat_name/:catId', exact: true, name: 'Collection', component: VIEWMOREPASS },
  { path: '/collection', exact: true, name: 'collection', component: COLLECTION_DATA },
  { path: '/playlist', exact: true, name: 'Playlist', component: PLAYLIST_DATA },
  { path: '/season/:season_name/:season_id', exact: true, name: 'Seasons', component: SEASON_EPISODES },
  { path: '/playlist/:name/:id', exact: true, name: 'Playlist', component: PLAYLIST_VIDEOS },
  { path: '/mercy_user/profile/:user_id', exact: true, name: 'UserProfile', component: USER_PROFILE },
  { path: '/myprofile', exact: true, name: 'UserProfile', component: USER_PROFILE },
  { path: '/contactus', exact: true, name: 'ContactUs', component: CONTACTUS },
  //{ path: '/aboutus', exact: true, name: 'aboutus', component: CONTACTUS },
  { path: '/ticket', exact: true, name: 'ticket', component: TICKET },
  { path: '/transaction', exact: true, name: 'transcation', component: TRANSCATION },
  { path: '/Quranpdf', exact: true, name: 'quranpdf', component: QURANPDF },
  { path: '/subscription', exact: true, name: 'payment', component: PAYMENT },
  // { path: '/checkout', exact: true, name: 'payment', component: PAYMENT_REDIRECT },
  { path: '/payment-response', exact: true, name: 'payment-response', component: PAYMENT_RESPONSE },
  // { path: '/getuseremail', exact: true, name: 'getuseremail', component: EmailModal },
  //{ path: '/fail', exact: true, name: 'payment_fail', component: PAYMENT_FAIL },
  
  { path: '/payment/subscription/:planid/:couponCode?', exact: true, name: 'payment', component: PAYMENT },
  { path: '/agentcommission', exact: true, name: 'agentcommission', component: AGENTCOMMISSION },
  { path: '/mercyvideo/:id', exact: true, name: 'mercyhd', component: MERCYVIDEO },
  { path: '/quran/:id', exact: true, name: 'quran', component: QURAN },
  { path: '/explore', exact: true, name: 'explore', component: EXPLORE },
  { path: '/kids/:id', exact: true, name: 'kids', component: KIDS },
  { path: '/livetv/:id', exact: true, name: 'livetv', component: LIVETV },
  { path: '/subscribe', exact: true, name: 'subscribe', component: SUBSCRIBE },
  { path: '/foropportunities', exact: true, name: 'foropportunities', component: OPPORTUNITIES },
  { path: '/appsetting', exact: true, name: 'appsetting', component: APPSETTING },
  { path: '/foradvertising', exact: true, name: 'foradvertising', component: ADVERTISING },
  { path: '/youraccount', exact: true, name: 'youraccount', component: YOURACCOUNT },
  { path: '/privacypolicy', exact: true, name: 'privacypolicy', component: PRIVACYPOLICY },
  { path: '/faq', exact: true, name: 'faq', component: FAQ },
  { path: '/termcondition', exact: true, name: 'termcondition', component: TERMCONDITION },
  { path: '/refundpolicy', exact: true, name: 'refundpolicy', component: REFUNDPOLICY },
  { path: '/watchlist', exact: true, name: 'mylist', component: MYWATCHLIST },
  { path: '/userhistory', exact: true, name: 'userhistory', component: MYHISTORY },
  { path: '/support/help', exact: true, name: 'support/help', component: SUPPORT },
  { path: '/aboutus', exact: true, name: 'aboutus', component: ABOUTUS },
  { path: '*', exact: true, name: 'Not Found', component: NOT_FOUND }
];

export default routes;
