import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/animate.css';
import './assets/css/App.css';
import './assets/css/index.css';
import './assets/css/player.css'
import './assets/css/player_controls.css'
import App from './App';
import { BrowserRouter, Router, Link, Route, Switch, HashRouter, Redirect } from "react-router-dom";
import Not_Found from './component/not_found/not_found.js';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Emailpopup from './container/Modal/modalshow';

class Result extends React.Component {
  componentDidMount() {
    this.getGeoLocation()
  }
  getGeoLocation = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      localStorage.setItem('countrycode', data.country)
    }).catch((error) => {
      console.log(error);
    });
  }
  render() {
    return (
      <>
        <div id="snackbar" className="transition d-flex align-items-center">
          <span className="msg"></span>
          <Button className="ml-auto closeSnackBar"><CloseIcon className="text-white" /></Button>
        </div>
        <HashRouter>
          <Switch>
            <Route exact path="/getuseremail" component={Emailpopup} />
            <Route path="/" component={App} />
          </Switch>
        </HashRouter>        
      </>
    )
  }
}
ReactDOM.render(<Result />, document.getElementById('root'));
