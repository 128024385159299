import React from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery'
import Social from '../Social/Social.js';

class Footer extends React.Component {
    render() {

    
        return (
        <>
        <footer className="home_footer">
            <article className="footer_t">
                <aside className="container-fluid p-0">
                    <div className="row">
                        
                        <div className="col-md-7 d-flex align-items-center pr-0">
                        <Link to="/"><img src="images/logo_white.png" alt="" className="logo" /></Link>

                            <div className="box  text-left ml-3">
                                <ul className="links">
                                    <li><Link to="/aboutus" className="border-effect" >About Us</Link></li>
                                    <li><Link to="/termcondition"  className="border-effect">Terms & Condition</Link></li>
                                    <li><Link to="/privacypolicy" className="border-effect">Privacy Policy</Link></li>                                   
                                    <li><Link to="/refundpolicy" className="border-effect">Refund Policy</Link></li>
                                    <li><Link to="/faq" className="border-effect">Faq</Link></li>
                                    <li><Link to="/contactus"  className="border-effect">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-md-5 d-flex align-items-center">
                            <div className="box r d-flex w-100">
                                <Social/>
                               
                                <div className="app-section">
                                    <h5>Available on</h5>
                                    <ul className="list list-inline mb-0">
								<li className="list-inline-item">
									<a href="#" target="_blank" className="cursor btn btn-round btn-lg text-white d-flex align-items-center text-left">
										<div className="icon mr-2">
											<i className="fa fa-apple" aria-hidden="true"></i>
										</div>

										<div>
											<p className="mb-0">Download from</p>
											<h4 className="mb-0">App Store</h4>
										</div>
									</a>
								</li>


								<li className="list-inline-item">
									<a  href="#" target="_blank"  className="cursor btn btn-round btn-lg text-white d-flex align-items-center text-left">
										<div className="icon mr-2">
											<i className="fa fa-android" aria-hidden="true"></i>
										</div>

										<div>
											<p className="mb-0">Download from</p>
											<h4 className="mb-0">Google Play</h4>
										</div>
									</a>
								</li>

							</ul>
                                  
                                </div>
                            </div>
                        </div> */}
                    </div>
                </aside>
                <div className="clearfix"></div>
            </article>
           
              
        </footer>


        </>)
    }
}
export default Footer;
