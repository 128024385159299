import React from 'react';
import Signup from '../../container/Signup/signup';
import PhoneLogin from '../../container/PhoneLogin/PhoneLogin';
import PhoneLoginOtp from '../../container/PhoneLogin/PhoneLoginotp';
import FixedPlanBoxC from '../../container/FixedPlanBox/fixedPlanBox';
import axios from 'axios'
class Login extends React.Component {
  state = {
    signUp: false,
    phoneLogin: true,
    phoneLoginOtp: false,
    fixedPlanBox: false,
    openUrl: '',
    mobile_num: '',
    token: '',
    response_user_id: '',
    language: '',
    uname: '',
    loginType: ''

  }
  componentDidMount() {
    // ade46788d77e90ed7e532ce83873716e
    // 2882019856
    // localStorage.setItem('token','5e4ba595fc7379577492eae26eedd746')
    // localStorage.setItem('userid',327)
  }
  
  openOTP = (mobile_num, token, contry, loginType) => {
    alert("mobile_num=" + mobile_num + "token=" + token + "contry=" + contry +  "loginType=" + loginType);
    this.setState({ phoneLoginOtp: true, phoneLogin: false, signUp: false, fixedPlanBox: false, mobile_num: mobile_num, token: token, language: contry, loginType: loginType })
  }
  
  openSignup = (response_user_id) => {
    //alert('openSignup');
    this.setState({ phoneLoginOtp: false, phoneLogin: false, signUp: true, fixedPlanBox: false, response_user_id: response_user_id })
  }
  openMobile = () => {

    this.setState({ phoneLoginOtp: false, phoneLogin: true, signUp: false, fixedPlanBox: false, response_user_id: '', token: '', mobile_num: '' })
  }
  openPlanF = (token, userid) => {
    //alert('openFixedPlan');
    this.setState({ phoneLoginOtp: false, phoneLogin: false, signUp: false, fixedPlanBox: true, response_user_id: userid, token: token, mobile_num: '' })
  }
  render() {
    if (this.state.phoneLogin) {
      return (<div className="login_f"><PhoneLogin openOTP={this.openOTP.bind(this)} {...this.props} /></div>)
    }
    if (this.state.phoneLoginOtp) {
      return (<div className="login_f">
        <PhoneLoginOtp
          mobile_num={this.state.mobile_num}
          token={this.state.token}
          language={this.state.language}
          loginType={this.state.loginType}
          {...this.props} /></div>)
    }
    if (this.state.signUp) {
      return (<div className="login_f"><Signup openOTP={this.openOTP.bind(this)} mobile_num={this.state.mobile_num} openPlanF={this.openPlanF.bind(this)} token={this.state.token} user_id={this.state.response_user_id} {...this.props} /></div>)
    }
    if (this.state.fixedPlanBox) {
      return (<div className="login_f"><FixedPlanBoxC mobile_num={this.state.mobile_num} token={this.state.token} user_id={this.state.response_user_id} {...this.props} /></div>)
    }

  }
}
export default Login;
