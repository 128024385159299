import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import Authenticator from '../Authentication/Authentication'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { SEARCH, COUNTRYCODE, PARTNER_ID, USERID } from '../../url';
import VideoCard from '../../component/VideoCard/VideoCard';

class SearchComponent extends React.Component {
  state = { keyword: '', searchResult: [], isLoading: false, keywords: '',searchResultLength:1 }

  getSearchData = async (e) => {
    e.preventDefault();
    if (e.target.keyword.value.trim() == '' || e.target.keyword.value.trim() == "/" || e.target.keyword.value.trim() == "?") {
      return false;
    }
    this.props.history.push(`/mercytv_search/search_keyword=/Mercytv@523/${e.target.keyword.value}`)
    document.getElementById('keyword').value = "";
    $('.header_search-panel').fadeOut(200);
    $('body,html').removeClass('overflow-hidden');
    $('body,html').animate({
      scrollTop: '0'
    }, 10);
  }

  getSearch = async (keyword) => {
    var formData = new FormData();
    // formData.append('userid', USERID);
    formData.append('tag', keyword);
    // formData.append('countrycode', COUNTRYCODE);
    // formData.append('partnerid', PARTNER_ID);
    let response = await axios.post(SEARCH, formData, { headers: { 'token': localStorage.getItem('token') } });
    if (response.data.status === 'Success') {
      var result = response.data;
      this.setState({ searchResult: result.data, isLoading: true,searchResultLength: result.data.length })
      setTimeout(() => this.setState({ isLoading: true }), 1000);
    }
    else {
      //this.props.history.push('/not_found');
    }
  }

  search = (ele) => {
    if (ele.target.value != "") {
      this.getSearch(this.state.keywords);
      this.setState({ keywords: ele.target.value });
      //console.log(this.state.keywords);
    }
    else {
      this.setState({ keywords: "" });
    }
  }

  closeSearch = () => {
    $('.header_search-panel').fadeOut(200);
    document.getElementById('keyword').value = "";
    $('.search_results').html("");
    this.getSearch(this.state.keywords);
    this.setState({ keywords: "" });
    this.getSearch("");
  }

  render() {
    const { searchResult } = this.state
    return (
      <>
        <div className="header_search-panel">
          <div className="search_header d-flex align-items-center m-auto">
            <img src="images/logo_white.png" alt="" className="logo" />
            <div className="m-auto search_wrap">
              <form className="search_f" onSubmit={this.getSearchData}>
                <input type="text" placeholder="Search for Movies, Shows, Channels etc." id="keyword" required="required" name="keyword" autoComplete="off" value={this.state.keywords}
                  onChange={this.search} />
                <Button type="submit"><SearchIcon className="text-white" /></Button>
              </form>
            </div>
            <a className="cursor ml-auto close-search" onClick={this.closeSearch}>
              <CloseIcon className="text-white res-hide" />
              <ArrowBackIcon className="text-white d-none res-show" />
            </a>
          </div>


          <div className="container-fluid  w-100">
            <div className="result-body">
              {
                this.state.keywords && 
                <>
                <p className="font-22">{searchResult.length} search result for  
                <span className="ml-2">'{this.state.keywords}'</span></p>
             
                <div className="d-flex flex-wrap search_results videos_thumb ">
                  {searchResult.map((res, index) => {
                    return (
                      <div className="item item_box_wrapper transition" key={index}>
                        <VideoCard
                          videoLink={`/video/${(res.name.replace(/\s/g, '')).toLowerCase()}/${res.entryid}`}
                          states={this.state}
                          response={res}
                          imgSrc={res.thumburl.h_thumburl}
                          title={res.name}
                          duration={res.duration} />
                      </div>

                    )
                  })}
                </div>
                </>
              }
              {
                (this.state.searchResultLength===0 && this.state.keywords)?
                <div className="text-center">
                  <img src="images/not_found.png" width="250" className="not_found"/>
                  <h1 className="text-white text-center font-24 text-y">Nothing found!</h1>
                  <p className="text-center font-16">Try searching for something else</p>
                </div>:""
              }
            </div>
          </div>
        </div>
      </>)
  }
}
export default Authenticator(SearchComponent);
