import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoaderCircle = (props) => {
    return (
        <>
            <div className={`loader_fixed_full_wrapper ${props.data_class}`}>
                <div className={`loader_fixed_full d-flex align-items-center justify-content-center ${props.data_position}`}>
                    <CircularProgress />
                </div>
            </div>
        </>
    );
}

export default LoaderCircle;