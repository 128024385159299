import React from 'react'
import axios from 'axios';
import {ORDERSTATUS,PARTNER_ID,UUID} from './url'
class PaymentRedirect extends React.Component{
    //console.log(this.props.match.params.orderid);
    //console.log(this.props.match.params.tokenid);
    state={
      order_id:'',
      orderStatusData:'',
      isRefreshLoader:false,
      isPaySuccess:false,
      isPayFailAndAbort:false,
      getorderid:'',
      trans_id:'',
      amount:''
      }
      componentDidMount()
      {
        this.setState({order_id:this.props.orderid},function(){
          this.getData();
        })
      }
 getData=async()=>{
    var formData=new FormData();
    formData.append('partnerid',PARTNER_ID);
    formData.append('orderid',this.state.order_id);
    formData.append('uuid',UUID);
    let response= await axios.post(ORDERSTATUS,formData);
    if(response.status=='200')
      {   
         if(response.data.status===101)
          {
               console.log("Error")
          }
          else{
          var responseData=response.data.Result;
          console.log(responseData[0].orderid)
          const getorderid=responseData[0].orderid;
          const orderStatus=responseData[0].order_status;
          const responseToken=responseData[0].tlcid;
          const trans_id=responseData[0].trans_id;
          const amount=responseData[0].amount;
          // localStorage.setItem('userid',responseData[0].userid)
          console.log(responseToken);
          if(orderStatus==='Aborted'){
              setTimeout(()=>this.setState(
               {
                isRefreshLoader:true,
                isPayFailAndAbort:true,
                isPaySuccess:false,
                getorderid:getorderid,
                trans_id:trans_id,
                amount:amount
               }
               ),1000)
          }
          if(orderStatus==='Success'){
            localStorage.setItem('userid',responseData[0].userid);
            localStorage.setItem('token',responseToken);
            setTimeout(()=>this.setState(
             {
              isRefreshLoader:true,
              isPayFailAndAbort:false,
              isPaySuccess:true,
              getorderid:getorderid,
              trans_id:trans_id,
              amount:amount
             }
             ),1000)
        }
         
          //setTimeout(()=>this.setState({isRefreshLoader:true,isPayFailAndAbort:true,isPaySuccess:false}),1000)
          //setTimeout(()=>this.props.history.push('/paymentstaus/'+orderStatus),2000);
          }
        }
       
        

      }
      payAgain=(e)=>{
      e.preventDefault();
      this.props.history.push('/');
     }
     goToHome=(e)=>{
      e.preventDefault();
      this.props.history.push('/');
     }
render()
      {
        if(!this.state.isRefreshLoader)
        {
          return (
          <div align="center">
            <p class="font-size14">Please dont refresh page or leave this page or back button until your process  complete</p> <img alt="playflix" src="http://cdnbiocine.planetcast.in/biocine/images/icons/fluid-loader.gif" class="spinner" /></div>
          )
          }
          if(this.state.isPaySuccess)
          {
             return (
              <div> 
              <div class="popup_l" style={{backgroundColor: "#fff"}} ><center>  <a href="#" ><img src="http://otm.s.llnwi.net/hls/vod/playflix/image/client_logo.png" height="85" /></a></center>  </div>
              <div align="center" height="100%" className="container"  >
               <div className="row">
                  <div className="col-sm-8">
                  <h3> </h3>
                  <div className="col-sm-12"  > </div>
                    <div className="col-sm-12" align="center" style={{padding: '10px'}} >
                  <h5>  Your Order ID #  <span style={{color: "blue"}} > {this.state.getorderid} </span>is    Success  </h5>

                    </div>

                    <div className="col-sm-12" align="center" style={{padding: '10px'}}>
                    <h5>  Amount is #  <span style={{color: "blue"}}> {this.state.amount}   </span>    </h5>
                    </div>

                    <div className="col-sm-12" align="center" style={{padding: '10px'}}>
                    <h5> Payment Reference no: <span  style={{color: "blue"}} >  {this.state.trans_id}   </span>    </h5>
                    </div>
                    <div className="col-sm-12" align="center"  style={{textTransform: 'capitalize',color: "black"}}>
                    <br/> You may use this number for any future communication.
                    </div>
                 </div>
               </div>
             </div>
             {this.state.isPaySuccess && <div class="buttons">
            <button type="button" onClick={this.goToHome}>Go To Home</button>
            </div>}
             {!this.state.isPaySuccess && <div class="buttons">
            <button type="button" disabled>Loading...</button>
            </div>}
             </div>
             )
          }
          if(this.state.isPayFailAndAbort)
          {
             return (
              <div> 
              <div class="popup_l" style={{backgroundColor: "#fff"}} ><center>  <a href="#" ><img src="http://otm.s.llnwi.net/hls/vod/playflix/image/client_logo.png" height="85" /></a></center>  </div>
              <div align="center" height="100%" className="container"  >
               <div className="row">
                  <div className="col-sm-8 m0">
                  <h3> </h3>
                  <div className="col-sm-12"  > </div>
                    <div className="col-sm-12" align="center" style={{padding: '10px'}} >
                  <h5 className="text-shadow">  Your Order ID #  <span style={{color: "blue"}} > {this.state.getorderid} </span>is    Aborted  </h5>

                    </div>

                    <div className="col-sm-12" align="center" style={{padding: '10px'}}>
                    <h5 className="text-shadow">  Amount is #  <span style={{color: "blue"}}> {this.state.amount}   </span>    </h5>
                    </div>

                    <div className="col-sm-12" align="center" style={{padding: '10px'}}>
                    <h5 className="text-shadow"> Payment Reference no: <span  style={{color: "blue"}} >  {this.state.trans_id}   </span>    </h5>
                    </div>
                    <div className="col-sm-12" align="center"  style={{textTransform: 'capitalize',color: "black"}}>
                    <br/> You may use this number for any future communication.
                    </div>
                 </div>
               </div>
             </div>
             {this.state.isPayFailAndAbort && <div class="buttons btn-play">
            <button type="button" className="play-btn" onClick={this.payAgain}>Pay Again</button>
            </div>}
             {!this.state.isPayFailAndAbort && <div class="buttons">
            <button type="button" disabled>Loading...</button>
            </div>}
             </div>
             
             )
          }
          
    }

}
export default PaymentRedirect;