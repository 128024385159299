import React, { useState } from 'react';
import axios from 'axios';
import { OTPSIGNIN, LOGIN, REGISTER, COUNTRYCODE, PARTNER_ID, GET_TOKEN, USERID, UUID, NAME, LOGOUT, OS, VERIFYCODE, FORGOTPASSWORDV1, RESET_PASSWORD, RESETPASSWORD, REGION, FACEBOOKAPP_ID, GOOGLECLIENT_ID } from '../../url';
import OtpTimer from 'otp-timer'
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import { Helmet } from "react-helmet";
import { MobileCode } from '../countrycode'
import Footer from '../../component/footer/footer'
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

import LoaderCircle from '../../component/loader/LoaderCircle.js';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Button from '@material-ui/core/Button';
import SocialLogin from '../PhoneLogin/SocialLogin';
import PublicIcon from '@material-ui/icons/Public';

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 750,
});

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});



class PhoneLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile_num: '', isSubmit: false,
      device_info: [], user_id: '', fname: '', isLogin: false,
      isActive: false, isOpenRefCode: false, useRefCode: '',
      openLoginForm: true, openRegisterForm: false, openForgotPassword: false,
      openVerifyOTPForm: false,
      openResetPassword: false,
      selected: '+91',
      hasError: false,
      selectedDate: new Date(),
      showModal: false,
      isloginwithmobile: false,
      isEmail: '',
      uname: '',
      l_email: '',
      l_numbermobile: '',
      l_pwd1: '',
      l_pwd2: '',
      countrycode: '',
      forgotEmail: '',
      forgotMobile: '',
      passwordreset: '',
      confirmpasswordreset: '',
      loginMobile: '',
      loginPassword: '',
      loginEmail: '',
      codereset: '',
      resetOTP1: '',
      resetOTP2: '',
      resetOTP3: '',
      resetOTP4: '',
      emailOTP1: '',
      emailOTP2: '',
      emailOTP3: '',
      emailOTP4: '',
      countries: [],

    }
    this.handelchangeInput = this.handelchangeInput.bind(this);
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date })
  }
  handelchangeInput(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange(value) {
    this.setState({ selected: value });
  }

  onValueChange = (event) => {
    this.setState({
      selectedOption: event.target.value
    });
  }

  async componentDidMount() {
    window._paq.push(['setDocumentTitle', 'Login']);
    window._paq.push(['trackPageView']);
    let internetConnCheck = navigator.onLine ? true : false;
    if (internetConnCheck) {
      const resdata = await fetch(GET_TOKEN, {
        method: 'GET',
        //make sure to serialize your JSON body
        body: null
      }).then((response) =>
        response.json()
      ).catch(function (error) {
        console.log(error);
      });

      if (resdata && resdata.status === 'Success') {
        //alert(response.data.token);
        let gentoken = resdata.token;
        if (gentoken) {
          localStorage.setItem('webtoken', gentoken);

          //country code,name api call
          const data = new FormData();
          data.append("region_type", 1);

          const countries = await fetch(REGION, {
            method: 'POST',
            headers: {
              'token': localStorage.getItem('webtoken'),
            },
            //make sure to serialize your JSON body
            body: data
          }).then((response) =>
            response.json()
          )
          this.setState({ countries });
        }
      }
    } else {
      console.log("You are offline")
    }
  }
  checkOTP = (e) => {
    // debugger
    if (e.target.value !== '') {
      this.setState({ [e.target.name]: e.target.value })
      let input = e.target.getAttribute('id');
      if (input !== 'emailOTP4') {
        e.target.nextSibling.focus();
      }
    }


  }
  checkOTPforgot = (e) => {
    // debugger
    if (e.target.value !== '') {
      this.setState({ [e.target.name]: e.target.value })
      let input = e.target.getAttribute('id');
      if (input !== 'resetOTP4') {
        e.target.nextSibling.focus();
      }
    }


  }
  isOTPEmpty = (e) => {
    // console.log('');
  }

  openGoogleLogin = () => {
    window._paq.push(['setDocumentTitle', 'Login']);
    window._paq.push(['trackPageView']);
    this.setState({ openLoginForm: false, openRegisterForm: false, openForgotPassword: false, openResetPassword: false, openVerifyOTPForm: false, })
  }
  openLogin = () => {
    window._paq.push(['setDocumentTitle', 'Login']);
    window._paq.push(['trackPageView']);
    this.setState({ openLoginForm: true, openRegisterForm: false, openForgotPassword: false, openResetPassword: false, openVerifyOTPForm: false, })
  }
  openVerifyOTP = () => {
    window._paq.push(['setDocumentTitle', 'Verfiy OTP']);
    window._paq.push(['trackPageView']);

    this.setState({ openVerifyOTPForm: true, openLoginForm: false, openRegisterForm: false, openForgotPassword: false, openResetPassword: false })
  }
  openRegister = () => {
    window._paq.push(['setDocumentTitle', 'Sign Up']);
    window._paq.push(['trackPageView']);
    this.setState({ openLoginForm: false, openRegisterForm: true, openForgotPassword: false, openVerifyOTPForm: false, })
  }
  openForgotPasswordForm = () => {
    window._paq.push(['trackPageView', 'Forgot Password']);
    this.setState({ openLoginForm: false, openRegisterForm: false, openForgotPassword: true, openVerifyOTPForm: false, })

  }
  openResetPassword = () => {
    this.setState({ openLoginForm: false, openRegisterForm: false, openForgotPassword: false, openResetPassword: true, openVerifyOTPForm: false, })
  }
  otpGenerate = (e) => {
    e.preventDefault();
    let pattern = new RegExp(/[~`!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
    let letters = new RegExp(/^[a-zA-Z]/);
    let email_reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let mobilevalidation = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g
    let passwordcase = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16})");
    let uname = this.state.uname
    let email = this.state.l_email
    let isloginwithmobile = this.state.isloginwithmobile
    let numbermobile = this.state.l_numbermobile
    let password = this.state.l_pwd1
    let confirmpassword = this.state.l_pwd2
    let countrycode = this.state.countrycode

    if (uname === '') {
      $('#snackbar .msg').html("Username is Required!");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');

      }, 5000);
      return false;
    }
    if (pattern.test(uname)) {
      $('#snackbar .msg').html("Username should not contain any special characters");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');

      }, 5000);
      return false;
    }

    if (/\d/.test(uname)) {
      $('#snackbar .msg').html("Username should not contain any numbers");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 5000);
      return false;
    }
    if (isloginwithmobile !== true) {
      if (email === '') {
        $('#snackbar .msg').html("Email id is Required!");
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-danger');
        }, 5000);
        return false;
      }
      if (!email_reg.test(String(email).toLowerCase())) {
        $('#snackbar .msg').html("Email id is wrong");
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-danger');
        }, 5000);
        return false;
      }
    } else {
      if (numbermobile === '') {
        $('#snackbar .msg').html("Mobile Number is Required!");
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-danger');
        }, 5000);
        return false;
      }
      if (!mobilevalidation.test(numbermobile)) {
        $('#snackbar .msg').html("Mobile Number id is wrong");
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-danger');
        }, 5000);
        return false;
      }
    }

    if (password === '') {
      $('#snackbar .msg').html("Password is Requied!");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 5000);
      return false;
    }

    if (!passwordcase.test(password)) {
      $('#snackbar .msg').html("Password must contain at least 1 lowercase <br> must  contain at least 1 uppercase <br>must contain at least 1 numeric character<br>must be 8 characters or 16 characters <br>must contain at least 1 special character");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 10000);
      return false
    }

    if (password !== confirmpassword) {
      $('#snackbar .msg').html("Password and confirm passsword are not same");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 5000);
      return false;
    }
    if (countrycode === '') {
      $('#snackbar .msg').html("Country is Required!");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 5000);
      return false;
    }
    else {
      var generateOtp = new FormData();
      if (isloginwithmobile !== true) {
        generateOtp.append('email', email)
        generateOtp.append('account_type', 2);
      }
      else {
        generateOtp.append('mobile', '91' + numbermobile);
        generateOtp.append('account_type', 1);
      }
      generateOtp.append('country', countrycode);
      generateOtp.append('tag', 0);
      //Api call here
      axios.post(OTPSIGNIN, generateOtp, {
        headers: {
          'token': localStorage.getItem('webtoken')
        }
      })
        .then(response => {

          if (response.data.status === "Success") {

            $('#snackbar .msg').html(response.data.data);
            $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
            setTimeout(function () {
              $('#snackbar').removeClass('show').removeClass('bg-success');
            }, 3000);
            this.openVerifyOTP()
          }
        })
    }
    // let email = this.state.l_email
    // this.openVerifyOTP()
  }
  formSubmit = (e) => {
    e.preventDefault();

    let uname = e.target.uname.value.trim();
    let email = e.target.l_email.value.trim();
    let pass_word = e.target.pass_word.value.trim();
    let cpass_word = e.target.cpass_word.value.trim();
    let countrycode = e.target.countrycode.value.trim();
    //alert(countrycode)
    //let language = e.target.lang_reg.value;
    let flag = 0;

    // if (uname != "") {
    //   let strArr = new Array();
    //   strArr = uname.split(" ");
    //   console.log(strArr);
    //   if (strArr.length > 1) {
    //     flag = 1;
    //   }
    // }

    if (uname == '') {
      /*toast("please fill username Name", { transition: Zoom, });*/
      $('#snackbar .msg').html("Please enter your name");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 3000);
      return false;
    }

    let pattern = new RegExp(/[~`!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
    let letters = new RegExp(/^[a-zA-Z]/);
    let email_reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let password = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16})");

    // if (uname.length <= 6) {
    //   $('#snackbar .msg').html("Username character length must be greater than 6  ");
    //   $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
    //   setTimeout(function () {
    //     $('#snackbar').removeClass('show').removeClass('bg-danger');

    //   }, 5000);
    //   return false;
    // }

    // if (uname.length > 32) {
    //   $('#snackbar .msg').html("Username should not greater than 32 characters");
    //   $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
    //   setTimeout(function () {
    //     $('#snackbar').removeClass('show').removeClass('bg-danger');

    //   }, 5000);
    //   return false;
    // }
    if (flag == 1) {
      $('#snackbar .msg').html("Username should not contain any space");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');

      }, 5000);

      return false;
    }
    if (pattern.test(uname)) {
      $('#snackbar .msg').html("Username should not contain any special characters");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');

      }, 5000);
      return false;
    }

    if (/\d/.test(uname)) {
      $('#snackbar .msg').html("Username should not contain any numbers");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 5000);
      return false;
    }

    if (!email_reg.test(String(email).toLowerCase())) {
      $('#snackbar .msg').html("Email id is wrong");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 5000);
      return false;
    }

    if (!password.test(pass_word)) {
      $('#snackbar .msg').html("Password must contain at least 1 lowercase <br> must  contain at least 1 uppercase <br>must contain at least 1 numeric character<br>must be 8 characters or 16 characters <br>must contain at least 1 special character");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 10000);
      return false
    }

    // if (!password.test(pass_word)) {
    //   $('#snackbar .msg').html("Password must contain at least 1 lowercase <br> must  contain at least 1 uppercase <br>must contain at least 1 numeric character<br>must be eight characters");
    //   $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
    //   setTimeout(function () {
    //     $('#snackbar').removeClass('show').removeClass('bg-danger');
    //   }, 10000);
    //   return false
    // }

    // if (pass_word.length > 16) {
    //   $('#snackbar .msg').html("Password must be less than 16 characters");
    //   $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
    //   setTimeout(function () {
    //     $('#snackbar').removeClass('show').removeClass('bg-danger');
    //   }, 5000);
    //   return false;
    // }

    if (pass_word != cpass_word) {
      $('#snackbar .msg').html("Password and confirm passsword are not same");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 5000);
      return false;
    }
    this.setState({ isSubmit: true });
    // this.getTokenRegister(e.target.l_email.value.trim(), e.target.pass_word.value.trim(), e.target.cpass_word.value.trim(), e.target.uname.value.trim());
    let token = localStorage.getItem('webtoken');
    this.setState({ token: token },
      function () {
        this.Resgister(email, pass_word, cpass_word, uname, token, countrycode)
      })
  }
  formLogin = (e) => {
    e.preventDefault();
    let email_reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let email = this.state.loginEmail
    let Mobile = this.state.loginMobile
    let password = this.state.loginPassword
    let isloginwithmobile = this.state.isloginwithmobile
    // debugger
    // debugger
    if (isloginwithmobile !== true) {
      if (email == '' && password == '') {
        $('#snackbar .msg').html('Must enter Email Address and password');
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
        }, 3000);
        this.setState({ watchList: true })
        return false
      }
      if (email == '') {
        $('#snackbar .msg').html('Email Address is Required!');
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-danger');
        }, 3000);
        return false;
      }
      if (!email_reg.test(String(email).toLowerCase())) {
        $('#snackbar .msg').html("Email id is wrong");
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-danger');
        }, 5000);
        return false;
      }

    }
    if (isloginwithmobile === true) {
      if (Mobile == '' && password == '') {
        $('#snackbar .msg').html('Must enter Mobile Number and password');
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
        }, 3000);
        this.setState({ watchList: true })
        return false
      }
      if (Mobile == '') {
        $('#snackbar .msg').html('Mobile Number is Required!');
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-danger');
        }, 3000);
        this.setState({ watchList: true })
        return false
      }

    }
    if (password == '') {
      $('#snackbar .msg').html('Password is Required!');

      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 3000);
      this.setState({ watchList: true })
      return false
    }
    else {
      var signinprocess = new FormData();
      if (isloginwithmobile !== true) {
        signinprocess.append('email_or_mobile', email); // mail id
      }
      else {
        signinprocess.append('email_or_mobile', Mobile); //phone
      }
      signinprocess.append('password', password); // password
      signinprocess.append('uuid', UUID); // uuid
      signinprocess.append('country', localStorage.getItem('countrycode')); // country

      // debugger
      axios.post(LOGIN, signinprocess, {
        headers: {
          'token': localStorage.getItem('webtoken')
        }
      }).then(response => {
        // debugger
        if (response.data.status === "Success") {
          // this.setState({ isLogin: true });
          let user_name = response.data.user_data['name'];
          let user_email = response.data.user_data['email'];
          // debugger
          let user_mobile = response.data.user_data.mobile;
          let userselect_country = response.data.user_data['country'];
          let token = localStorage.getItem('webtoken')
          localStorage.setItem('user_name', user_name)
          localStorage.setItem('user_email', user_email)
          localStorage.setItem('user_mobile', user_mobile)
          localStorage.setItem('userselect_country', userselect_country)
          localStorage.setItem('token', token)
          $('#snackbar .msg').html(response.data.data);
          $('#snackbar').addClass('show');
          setTimeout(function () {
            
            $('#snackbar').removeClass('show');
            // alert('hello world')
            window.location.href = "/"
            // return false;
          }, 3000);
          // window.location.href = "/"
          // window.location.assign('/')
        }
        // else {
        //   if (response.data.status === 'Failed') {
        //     $('#snackbar .msg').html(response.data.data);
        //     $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
        //     setTimeout(function () {
        //       $('#snackbar').removeClass('show').removeClass('bg-success');
        //     }, 3000);
        //   }
        // }
      }).catch((error) => {
        $('#snackbar .msg').html('You have Enter Invalid Email/Mobile or Password');
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-success');
        }, 3000);
      })
    }

  }
  formLogin2 = (e) => {
    e.preventDefault();
    //alert(e);
    let l_email = e.target.l_email.value;
    let l_pwd = e.target.l_pwd.value;
    //alert(l_email +"email ==pwd"+l_pwd);
    //let language = e.target.lang_login.value;
    let language = "EN";

    if ((e.target.l_email.value.trim() == '') && (e.target.l_pwd.value.trim() == '')) {
      //toast("Must enter username and password", { transition: Zoom, });
      $('#snackbar .msg').html('Must enter username and password');
      $('#snackbar').addClass('show');
      setTimeout(function () {
        $('#snackbar').removeClass('show');
      }, 3000);
      this.setState({ watchList: true })
      return false
    }
    this.setState({ isLogin: true });
    this.getTokenLogin(e.target.l_email.value.trim(), e.target.l_pwd.value.trim());
  }
  openVerifyOTPSubmit = async (e) => {
    e.preventDefault();
    let emailOTP1 = this.state.emailOTP1
    let emailOTP2 = this.state.emailOTP2
    let emailOTP3 = this.state.emailOTP3
    let emailOTP4 = this.state.emailOTP4
    let uname = this.state.uname
    let email = this.state.l_email
    let isloginwithmobile = this.state.isloginwithmobile
    let numbermobile = this.state.l_numbermobile
    let pass_word = this.state.l_pwd1
    let cpass_word = this.state.l_pwd2
    let countrycode = this.state.countrycode
    // debugger
    let finalotp = emailOTP1 + emailOTP2 + emailOTP3 + emailOTP4
    // alert('finalotp' + finalotp)
    if (emailOTP1 !== '' && emailOTP2 !== '' && emailOTP3 !== '' && emailOTP4 !== '') {
      var verifyOtp = new FormData();
      if (isloginwithmobile !== true) {
        verifyOtp.append('email', email);
        verifyOtp.append('account_type', 2);
      } else {
        verifyOtp.append('mobile', "91" + numbermobile);
        verifyOtp.append('account_type', 1);
      }
      // verifyOtp.append('email', state.reemail);
      // verifyOtp.append('account_type', 2);
      verifyOtp.append('tag', 1);
      verifyOtp.append('otp', finalotp);
      axios.post(OTPSIGNIN, verifyOtp, {
        headers: {
          'token': localStorage.getItem('webtoken')
        }
      }).then(response => {
        // debugger
        if (response.data.status === "Success") {

          $('#snackbar .msg').html(response.data.data);
          $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
          setTimeout(function () {
            $('#snackbar').removeClass('show').removeClass('bg-success');
          }, 3000);
          let token = localStorage.getItem('webtoken');
          this.setState({ token: token },
            function () {
              this.Resgister(email, pass_word, cpass_word, uname, token, countrycode, numbermobile, isloginwithmobile)
            })
        }
        // else{
        //   $('#snackbar .msg').html(response.data.data);
        //   $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
        //   setTimeout(function () {
        //     $('#snackbar').removeClass('show').removeClass('bg-success');
        //   }, 3000);
        // }

      }).catch((error) => {
        $('#snackbar .msg').html('Invalid OTP');
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-success');
        }, 3000);
      })
    }
  }

  formReset = async (e) => {
    // debugger
    document.querySelector('.loader_fixed_full_wrapper').style.display = "block";
    e.preventDefault();
    // var reset_email = e.target.l_email.value;
    var formData = new FormData();
    formData.append('partnerid', PARTNER_ID);
    if (this.state.isloginwithmobile !== true) {
      formData.append('email_or_mobile', this.state.forgotEmail);
    }
    else {
      formData.append('email_or_mobile', '91' + this.state.forgotMobile);
    }
    // formData.append('email', reset_email);

    await axios.post(FORGOTPASSWORDV1, formData).then((res) => {
      //console.log(res.data);
      var i = 0;
      if (res.data.status === "Success") {
        $('#snackbar .msg').html(res.data.data);
        $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          document.querySelector('.loader_fixed_full_wrapper').style.display = "none";
        }, 3000);
        this.setState({ openResetPassword: true, openForgotPassword: false, isEmail: this.state.forgotEmail })
      }
    }).catch((error) => {
      /**
      *If the response status code is 409 - Conflict, then we already have
      **/
      //alert(typeof error.response.status);
      if (error.response.status === 400) {
        // debugger
        //let msg = error.response.data.error;
        let msg = error.response.data.data ? error.response.data.data : error.response.data.error;
        //console.log(msg)
        $('#snackbar .msg').html(msg);
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          window.location.href = "/"
          return false;
        }, 2000);
      } else {
        console.log(error.message);
      }
      return false;
    });
  }

  formResetpassword = async (e) => {
    e.preventDefault();
    let passwordcase = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16})");
    var code = this.state.codereset;
    var newpass = this.state.passwordreset;
    var forgotMobile = this.state.forgotMobile
    var cpass = this.state.confirmpasswordreset;
    let resetOTP1 = this.state.resetOTP1
    let resetOTP2 = this.state.resetOTP2
    let resetOTP3 = this.state.resetOTP3
    let resetOTP4 = this.state.resetOTP4
    let finalOTP = resetOTP1+resetOTP2+resetOTP3+resetOTP4
    var email = this.state.isEmail;
    var msg = '';

    if (finalOTP === "") {
      msg = "Please enter code";
      $('#snackbar .msg').html(msg);
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show');

      }, 3000);
      return false;
    }

    if (newpass === "") {
      msg = "Please enter New apssword";
      $('#snackbar .msg').html(msg);
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show');

      }, 3000);
      return false;
    }
    if (!passwordcase.test(newpass)) {
      $('#snackbar .msg').html("Password must contain at least 1 lowercase <br> must  contain at least 1 uppercase <br>must contain at least 1 numeric character<br>must be 8 characters or 16 characters <br>must contain at least 1 special character");
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show').removeClass('bg-danger');
      }, 3000);
      return false
    }

    if (cpass === "") {
      msg = "Please enter Confirm apssword";
      $('#snackbar .msg').html(msg);
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show');

      }, 3000);
      return false;
    }

    if (newpass.length < 8) {
      msg = "New password must be 8 digits";
      $('#snackbar .msg').html(msg);
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show');

      }, 3000);
      document.querySelector('.loader_fixed_full_wrapper').style.display = "none";
      return false;
    }

    if (cpass.length < 8) {
      msg = "Confirm password must be 8 digits";
      $('#snackbar .msg').html(msg);
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show');
        document.querySelector('.loader_fixed_full_wrapper').style.display = "none";
      }, 3000);
      return false;
    }

    if (newpass !== cpass) {
      msg = "New apssword & Confirm password didn't match";
      $('#snackbar .msg').html(msg);
      $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      setTimeout(function () {
        $('#snackbar').removeClass('show');
        document.querySelector('.loader_fixed_full_wrapper').style.display = "none";
      }, 3000);
      return false;
    }

    var resetFormPassword = new FormData();
    resetFormPassword.append('partnerid', PARTNER_ID);
    // this.state.isloginwithmobile
    if (this.state.isloginwithmobile !== true) {
      resetFormPassword.append('email_or_mobile', email);
    } else {
      resetFormPassword.append('email_or_mobile', '91' + forgotMobile);
    }
    // resetFormPassword.append('email', email);
    resetFormPassword.append('code', finalOTP);
    resetFormPassword.append('password', newpass);

    await axios.post(RESETPASSWORD, resetFormPassword).then((resetPassword) => {
      //console.log(resetPassword)
      if (resetPassword.data.status === "Failed") {
        $('#snackbar .msg').html(resetPassword.data.data);
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          document.querySelector('.loader_fixed_full_wrapper').style.display = "none";
        }, 1000);
        return false;
      }
      if (resetPassword.data.status === "Success") {
        var message = resetPassword.data.data
        $('#snackbar .msg').html(message);
        $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
        setTimeout(function () {
          document.getElementById("resetPassForm").reset();
          $('#snackbar').removeClass('show');
          document.querySelector('.loader_fixed_full_wrapper').style.display = "none";
          window.location.href = "/"
        }, 3000);
      }
    }).catch((error) => {
      /**
    *If the response status code is 409 - Conflict, then we already have
    **/
      //alert(typeof error.response.status);
      if (error.response.status === 400) {
        let msg = error.response['data']['data'];
        $('#snackbar .msg').html(msg);
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          window.location.href = "/"
          return false;
        }, 2000);
      } else {
        console.log(error.message);
      }
    });
    return false;
  }

  formGoogleLogin = (e) => {
    e.preventDefault();
    return true;
  }

  getTokenLogin = (email_val, password_val, lang, l_phoneNumber, isloginwithmobile) => {
    var formData1 = new FormData();
    //formData1.append('partnerid', PARTNER_ID);
    axios.get(GET_TOKEN, formData1).then((response) => {
      if (response.data.status == 'Success') {
        this.setState({ token: response.data.token },
          function () {
            this.Login(email_val, password_val, response.data.token, l_phoneNumber, isloginwithmobile)
          })
      }
      else {
        //$('#mob_num').prop('disabled', false);
        this.setState({ isLogin: false });
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  Login = (email_val, password_val, token, l_phoneNumber, isloginwithmobile) => {
    document.querySelector('.loader_fixed_full_wrapper').style.display = "block";
    var formData = new FormData();
    formData.append('partnerid', PARTNER_ID);
    formData.append('uuid', UUID);
    // formData.append('email', email_val);
    if (isloginwithmobile !== true) {

      formData.append('email_or_mobile', email_val); // mail id
    }
    else {
      formData.append('email_or_mobile', l_phoneNumber); //phone
    }
    formData.append('type', 'WEB');
    formData.append('country', COUNTRYCODE);
    formData.append('password', password_val);
    //formData.append('language', "EN");
    axios.post(LOGIN, formData, {
      headers: {
        'token': localStorage.getItem('webtoken')
      }
    }).then((response) => {
      //console.log(response.data);
      if (response.data.status === "Success") {
        let user_name = response.data.user_data['name'];
        let user_email = response.data.user_data['email'];
        let userselect_country = response.data.user_data['country'];
        $('#snackbar .msg').html(response.data.data);
        $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
        }, 3000);
        localStorage.setItem('user_name', user_name)
        localStorage.setItem('user_email', user_email)
        localStorage.setItem('userselect_country', userselect_country)
        localStorage.setItem('token', token)
        //this.setState({ isLoading: true });
        setTimeout(() =>
          this.props.history.push('/')
          //window.location.href = "/"
          , 1000)
      }
      else {
        let msg = "Email or Password combination is incorrect";
        $('#snackbar .msg').html(msg);
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          window.location.href = "/"
        }, 3000);
        return false;
      }
    }).catch((error) => {
      /**
      *If the response status code is 409 - Conflict, then we already have
      **/
      //alert(typeof error.response.status);
      if (error.response.status === 400) {
        //let msg = "Email or Password combination is incorrect";
        let msg = error.response['data']['data'];
        $('#snackbar .msg').html(msg);
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          window.location.href = "/"
          return false;
        }, 2000);
      } else {
        console.log(error.message);
      }
    })
  }

  Resgister = (uregisemail, l_pwd1, l_pwd2, uname, token, countrycode, numbermobile, isloginwithmobile) => {
    let users_email = uregisemail; // email 
    let u_pwd = l_pwd2;
    let userName = uname;

    //perm email,password,uuid(required) and country(optional)
    var formData = new FormData();
    formData.append('partnerid', PARTNER_ID);
    formData.append('country', countrycode);
    formData.append('type', 'web');
    formData.append('uuid', UUID);
    formData.append('username', userName);
    formData.append('password', u_pwd);
    if (isloginwithmobile !== true) {
      formData.append('email', uregisemail);
      formData.append('account_type', 2);
    } else {
      formData.append('mobile', numbermobile);
      formData.append('account_type', 1);
    }

    //useRefCode
    axios.post(REGISTER, formData, {
      headers: {
        'token': token
      }
    }).then((response) => {
      //console.log(response);
      if (response && response.data.status === "Success") {
        let user_name = response.data.user_data['name'];
        let user_email = response.data.user_data['email'];
        let userselect_country = response.data.user_data['country'];
        //alert(response.data.data)
        $('#snackbar .msg').html(response.data.data);
        $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');

        setTimeout(function () {
          $('#snackbar').removeClass('show').removeClass('bg-danger');
          localStorage.setItem('user_name', user_name)
          localStorage.setItem('user_email', user_email)
          localStorage.setItem('userselect_country', userselect_country)

          let settoken = localStorage.setItem('token', token);
          if (settoken) {
            //this.props.history.push('/')
            window.location.href = "/"
          } else {
            $('#snackbar.msg').html("Some thing went wrong");
            window.location.href = "/"
          }
        }, 3000);
        /*toast(response.data.msg, { transition: Zoom, });*/
      } else {
        let msg = response.data.data
        $('#snackbar .msg').html(msg);
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          window.location.href = "/"
        }, 3000);
      }
    }).catch((error) => {
      /**
      *If the response status code is 409 - Conflict, then we already have
      **/
      if (error.response && error.response.status === 409) {
        //let msg = "Email id already registered with us";
        let msg = error.response['data']['data'];
        $('#snackbar .msg').html(msg);
        $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
        setTimeout(function () {
          $('#snackbar').removeClass('show');
          window.location.href = "/"
          return false;
        }, 2000);
      } else {
        console.log(error.message);
      }
    })
  }

  onChangeInput = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ mobile_num: e.target.value })
    }
  }

  logout = (uuid) => {
    var formData = new FormData();
    formData.append('userid', this.state.user_id);
    formData.append('countrycode', COUNTRYCODE);
    formData.append('partnerid', PARTNER_ID);
    formData.append('uuid', uuid);
    axios.post(LOGOUT, formData).then((response) => {
      if (response.data.status == 0) {
        // console.log(response);
        toast(response.data.msg, { transition: Zoom, });
        $('#uid_' + uuid).html('Logged Out');
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  ApplyRefCode = (e) => {
    e.preventDefault();
    let refCodes = e.target.refCodes.value
    if (refCodes == '') {
      toast("Please Enter Referral Code", { transition: Zoom, });
      return false;
    }
    this.getTokenRefCode(refCodes.trim());

  }
  changeinp = () => {
    this.setState({
      isloginwithmobile: !this.state.isloginwithmobile
    })
  }

  getTokenRefCode = (refcode) => {
    var formData1 = new FormData();
    formData1.append('partnerid', PARTNER_ID);
    axios.get(GET_TOKEN, formData1).then((response) => {
      if (response.status == '200') {
        //this.setState({ token: 'd365ab64a7c425b6ebfe13a88d2faeea' },
        this.setState({ token: response.data.Token },
          function () {
            this.SubmitRefcode(refcode, response.data.Token)
          })
      }
      else {

      }
    }).catch((error) => {
      console.log(error);
    })

  }
  SubmitRefcode = (refcode, token1) => {
    var formData = new FormData();
    formData.append('partnerid', PARTNER_ID);
    formData.append('code', refcode);
    axios.post(VERIFYCODE, formData, {
      headers: { 'token': token1 }
    }).then((response) => {
      // console.log(response);
      if (response.data.isRefCode == '1' && (response.data.isAgentCode == '0')) {
        toast("referral code apply Successfully", { transition: Zoom })
        setTimeout(() => this.setState({ isOpenRefCode: false }), 1000)
        this.setState({ useRefCode: refcode })
        //$('#refCodes').val('');

      }
      if (response.data.isRefCode == '0' && (response.data.isAgentCode == '0')) {
        toast("code is invalid", { transition: Zoom })
        setTimeout(() => this.setState({ isOpenRefCode: true }), 1000)
        //$('#refCodes').val('');
        return false

      }
      if ((response.data.isAgentCode == '1') && (response.data.isRefCode == '0')) {
        toast("Agent code apply Successfully", { transition: Zoom })
        setTimeout(() => this.setState({ isOpenRefCode: false }), 1000)
        this.setState({ useRefCode: refcode })
        //$('#refCodes').val('');

      }


    }).catch((error) => {
      console.log(error);
    })

  }
  OpenRefCode = () => {
    this.setState({
      isOpenRefCode: true
    })
  }
  skipRefCode = () => {
    this.setState({
      isOpenRefCode: false
    })
  }

  /* signup for social login */
  signup(res, type) {
    let loginDetails;
    //console.log(res);

    if (type === 'google') {
      //console.log(res);
      loginDetails = {
        provider: type,
        token: res.tokenId,
        authId: res.googleId,
        email: res.profileObj.email,
        name: res.profileObj.name
      };

    }
    if (type === 'facebook') {
      //debugger
      //console.log(res);
      // if (res.status === "unknown") {
      //   return true
      //   // debugger
      //   // let msg = "Revoke Authorise Sign up";
      //   // $('#snackbar .msg').html(msg);
      //   // $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
      //   // setTimeout(function () {
      //   //   $('#snackbar').removeClass('show');
      //   //   window.location.href = "/"
      //   //   return false;
      //   // }, 2000);
      // } 

      //else 
      if (res.status !== "unknown" || res.status !== "undefined") {
        loginDetails = {
          provider: type,
          token: res.tokenId,
          authId: res.userID,
          email: res.email,
          name: res.name
        };
      }
    }
    //console.log(loginDetails.authId)
    localStorage.setItem("socialAuthData", JSON.stringify(loginDetails))
    if (loginDetails.email) {
      SocialLogin('signup', loginDetails).then((response) => {
        //console.log(result);
        if (response.data.status === "Success") {
          $('#snackbar .msg').html(response.data.data);
          $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
          setTimeout(function () {
            $('#snackbar').removeClass('show');
          }, 3000);
          //this.setState({ isLoading: true });
          localStorage.removeItem('socialAuthData')
          setTimeout(() =>
            this.props.history.push('/')
            //window.location.href = "/"
            , 1000)
        }

      }).catch((error) => {
        /**
        *If the response status code is 409 - Conflict, then we already have
        **/
        if (error.response.status === 409) {
          console.log('error.response', error.response)
          let msg = "Session time out";
          $('#snackbar .msg').html(msg);
          $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
          setTimeout(function () {
            $('#snackbar').removeClass('show');
            window.location.href = "/"
            return false;
          }, 2000);
        } else {
          console.log(error.message);
        }
      });
    } else if (loginDetails.email === '' && loginDetails.email === undefined) {
      window.location.href = "#/getuseremail";
      return false
    }
  }

  responseGoogle = (response) => {
    // console.log(response)
    // console.log(response.profileObj.name)
    // if(response && response.profileObj.name !== undefined && response.profileObj.email !== undefined){
    //   localStorage.setItem('user_name', response.profileObj.name)
    //   localStorage.setItem('user_email', response.profileObj.email)
    // }
    localStorage.setItem('user_name', response.profileObj.name)
    localStorage.setItem('user_email', response.profileObj.email)
    this.signup(response, 'google');
  }

  responseFacebook = (response) => {
    //console.log("facebook console");
    this.signup(response, 'facebook');
  }

  render() {
    const { selected, hasError, countries } = this.state;

    $(document).on('click', '#login_form  .btn', function (e) {
      var ele = $(".login_form");
      var form = $(".login_form")[0];
      ele.find('.error').remove();

      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        form.classList.add('was-validated');
      }
    });

    $(document).on('click', '#signup_form  .btn', function (e) {
      var ele = $(".signup_form");
      var form = $(".signup_form")[0];
      ele.find('.error').remove();

      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        form.classList.add('was-validated');
      }
    });

    return (
      <>
        <LoaderCircle />
        <ul className="bubbles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <section className="login-bg d-flex align-items-center justify-content-center">
          <div className="login-card">
            <div className="text-center mb-4 wow fadeInUp">
              <img className="login_logo" src="images/logo_main.png" alt="" />
            </div>
            <div className="wow fadeInUp" data-wow-delay="0.3s">
              {this.state.openLoginForm &&
                <>
                  <form onSubmit={this.formLogin} id="login_form" className="login_form needs-validation" noValidate>

                    {
                      this.state.isloginwithmobile === false ? <div className="field icon-wrap form-group">
                        <input type="email" id="loginEmail" name='loginEmail' className="input  transition form-control" placeholder="Email Address" required value={this.state.loginEmail} onChange={this.handelchangeInput} />
                        <div className="invalid-feedback">Please enter email address</div>
                        <img src="images/email.svg" alt="Email" width="17" className="icon" />
                      </div> : <div className="field icon-wrap form-group">
                        <input type="tel" id="loginMobile" name='loginMobile' className="input  transition form-control" placeholder="Mobile Number" pattern="[0-9]{10}" maxlength="10" required value={this.state.loginMobile} onChange={this.handelchangeInput} />
                        <div className="invalid-feedback">Please enter Mobile Number</div>
                        <img src="images/ph2.png" alt="Email" width="17" className="icon" />
                      </div>
                    }
                    {
                      this.state.isloginwithmobile === false ? <div className='registerwithemial text-y cursor mb-2' onClick={this.changeinp}>Login With Mobile Number</div> : <div className='registerwithemial text-y cursor mb-2' onClick={this.changeinp}>Login With Email Address</div>
                    }
                    <div className="field icon-wrap  form-group">
                      <input type="password" id="loginPassword" name='loginPassword' className="input transition form-control" placeholder="Password" required value={this.state.loginPassword} onChange={this.handelchangeInput} />
                      <div className="invalid-feedback">Please enter Password</div>
                      <img src="images/pass.svg" alt="Password" width="17" className="icon" />
                      <VisibilityOffIcon className="show_password first  d-none" />
                      <VisibilityIcon className="show_password sec" />
                    </div>


                    <div className="d-flex mb-3">
                      <a className="cursor border-effect font-14 ml-auto link"
                        onClick={this.openForgotPasswordForm}>Forgot Password ?</a>
                    </div>

                    <Button type="submit" variant="contained" className="w-100 btn btn-lg btn-red" onClick={this.formLogin}>
                      Sign In Now
                    </Button>


                    <p className="text-center d-flex font-14 align-items-center justify-content-center mt-3">
                      <span className="mr-3">Don't have an account?</span>
                      <a className="cursor border-effect font-14 link" onClick={this.openRegister}>Sign Up</a></p>

                    <div className="or d-flex align-items-center justify-content-center">
                      <div className="line mr-1"></div>
                      <div className="icon"><div className="rounded-circle text-white font-16">OR</div></div>
                      <div className="line ml-1"></div>
                    </div>
                  </form>

                  {/* <div className="mt-2 socials d-flex align-items-center justify-content-between"> */}
                  <div className="mt-2 socials d-flex align-items-center justify-content-center">
                    {/* <Button variant="contained" className="btn bg-white text-black fb d-flex align-items-center">
                      <img src="images/fb.svg" alt="Facebook" width="18" />
                      <span className="ml-2">
                        <form onSubmit={this.responseFacebook}>
                          <FacebookLogin
                            appId={FACEBOOKAPP_ID}
                            autoLoad={false}
                            fields="name,email,picture"
                            cssClass="btn p-0"
                            textButton='Facebook'
                            render={renderProps => (
                              <Button
                                className="p-0"
                                onPress={this.handleFacebookLogin}
                                title="Continue with fb"
                                color="#4267B2">
                              </Button>

                            )
                            }
                            callback={this.responseFacebook}
                          />
                        </form>
                      </span>
                    </Button> */}

                    <form onSubmit={this.formGoogleLogin}>
                      <GoogleLogin
                        className="btn bg-white text-black fb d-flex align-items-center"
                        clientId={GOOGLECLIENT_ID}
                        buttonText="Google"
                        disabled={false}
                        // cookiePolicy={'single_host_origin'}
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        onClick={this.openGoogleLogin} >
                      </GoogleLogin>
                    </form>
                  </div>
                  {/* <p className="text-center mt-4 font-13 mb-0">By continiuing you agree to our <Link className="d-inline-block text-white border-effect">Terms & Conditions</Link> and <Link  className="d-inline-block text-white border-effect">Privacy Policy</Link></p> */}
                </>
              }

              {this.state.openRegisterForm &&
                <>
                  <form onSubmit={this.otpGenerate} id="signup_form" className="signup_form needs-validation" noValidate>
                    <div className="field icon-wrap form-group">
                      <input type="text" name="uname" id="uname" className="input transition form-control" placeholder="Your Name" required value={this.state.uname} onChange={this.handelchangeInput} />
                      <div className="invalid-feedback">Please enter your name</div>
                      <img src="images/user.svg" alt="name" width="18" className="icon"
                        style={{ opacity: '0.6' }} />
                    </div>
                    {
                      this.state.isloginwithmobile === false ? <div className="field icon-wrap form-group">

                        <input type="email" name="l_email" id="l_email" className="input transition form-control" autoComplete="off" placeholder="Email Address" required value={this.state.l_email} onChange={this.handelchangeInput} />
                        <div className="invalid-feedback">Please enter email address</div>
                        <img src="images/email.svg" alt="Email" width="17" className="icon" />
                      </div> : <div className="field icon-wrap form-group">

                        <input type="tel" name="l_numbermobile" id="l_numbermobile" className="input transition form-control" autoComplete="off" placeholder="Mobile Number" pattern="[0-9]{10}" maxlength="10" required value={this.state.l_numbermobile} onChange={this.handelchangeInput} />
                        <div className="invalid-feedback">Please enter Mobile Number</div>
                        <img src="images/ph2.png" alt="Email" width="17" className="icon" />
                      </div>
                    }

                    {
                      this.state.isloginwithmobile === false ? <div className='registerwithemial text-y cursor mb-2' onClick={this.changeinp}>Sign Up With Mobile Number</div> : <div className='registerwithemial text-y cursor mb-2' onClick={this.changeinp}>Sign Up With Email Address</div>
                    }

                    <div className="field icon-wrap form-group">
                      <input type="password" name="l_pwd1" id="l_pwd1" className="input transition form-control show_password_input" placeholder="Password" required value={this.state.l_pwd1} onChange={this.handelchangeInput} />
                      <div className="invalid-feedback">Please enter password</div>
                      <img src="images/pass.svg" alt="Password" width="17" className="icon" />
                      <VisibilityOffIcon className="show_password first  d-none" />
                      <VisibilityIcon className="show_password sec" />
                    </div>

                    <div className="field icon-wrap form-group">
                      <input type="password" name="l_pwd2" id="l_pwd2" className="input transition form-control" placeholder="Confirm Password" required value={this.state.l_pwd2} onChange={this.handelchangeInput} />
                      <div className="invalid-feedback">Please re-enter password</div>
                      <img src="images/pass.svg" alt="Password" width="17" className="icon" />
                      <VisibilityOffIcon className="show_password first  d-none" />
                      <VisibilityIcon className="show_password sec" />
                    </div>

                    <div className="field icon-wrap form-group">

                      <select name="countrycode" id="countrycode" className="form-control input transition" style={{ 'background-color': '#3b2a20', 'color': '#fff' }} required value={this.state.countrycode} onChange={this.handelchangeInput} >
                        <option value=''>Select Country</option>
                        {countries.data && countries.data.map(countryval => {
                          return (
                            <option key={countryval.code} value={countryval.code}>{countryval.country}</option>
                          );
                        })}
                        {/* <option key="IN" value="IN">India</option> */}
                      </select>
                      <div className="invalid-feedback">Please select country</div>
                      <PublicIcon className="icon" />
                    </div>

                    <Button type="submit" variant="contained" className="w-100 btn btn-lg btn-red" onClick={this.otpGenerate}>Sign Up Now</Button>
                    <p className="text-center d-flex font-14 align-items-center justify-content-center mt-3">
                      <span className="mr-3">Already have an account?</span>
                      <a className="cursor border-effect font-14 link" onClick={this.openLogin}>Sign In</a></p>
                  </form>

                  <div className="mt-2 socials d-flex align-items-center justify-content-between">
                    <Button variant="contained" className="btn bg-white text-black fb d-flex align-items-center">
                      <img src="images/fb.svg" alt="Facebook" width="18" />
                      <span className="ml-2">
                        <form onSubmit={this.responseFacebook}>
                          <FacebookLogin
                            appId={FACEBOOKAPP_ID}
                            autoLoad={false}
                            fields="name,email,picture"
                            cssClass="btn p-0"
                            textButton='Facebook'
                            render={renderProps => (
                              <Button
                                className="p-0"
                                onPress={this.handleFacebookLogin}
                                title="Continue with fb"
                                color="#4267B2">
                              </Button>

                            )
                            }
                            callback={this.responseFacebook}
                          />
                        </form>
                      </span>
                    </Button>

                    <form onSubmit={this.formGoogleLogin}>
                      <GoogleLogin
                        className="btn bg-white text-black fb d-flex align-items-center"
                        clientId={GOOGLECLIENT_ID}
                        disabled={false}
                        buttonText="Google"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        onClick={this.openGoogleLogin} >
                      </GoogleLogin>
                    </form>
                  </div>
                </>
              }
              {
                this.state.openVerifyOTPForm &&
                <>
                  <form onSubmit={this.openVerifyOTPSubmit} id='verifyotp' className='' noValidate >
                    <h3 className="text-center text-white mt-0 text-light" style={{ fontWeight: '400' }}>Verify Details</h3>
                    <p className="text-center text-light font-w-300 mt-0 font-16">Please Enter your OTP code sent to your {this.state.isloginwithmobile === false ? 'Email Address' : 'Mobile Number'}</p>
                    <p className="text-light d-flex align-items-center w-75 m-auto font-w-300">{this.state.isloginwithmobile === false ? this.state.l_email : this.state.l_numbermobile}  <a className="cursor border-effect text-y font-16 ml-auto" onClick={this.openRegister}>Edit</a></p>



                    <div className="d-flex align-items-center justify-content-center mt-3 mb-4 otp_box position-relative">
                      <input vlaue={this.state.emailOTP1} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} type="text" className="mr-3 input transition form-control" id="emailOTP1" name="emailOTP1" onChange={this.checkOTP} onBlur={this.isOTPEmpty} maxLength="1" />
                      <input vlaue={this.state.emailOTP2} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} type="text" className="mr-3 input transition form-control" id="emailOTP2" name="emailOTP2" onChange={this.checkOTP} onBlur={this.isOTPEmpty} maxLength="1" />
                      <input vlaue={this.state.emailOTP3} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} type="text" className="mr-3 input transition form-control" id="emailOTP3" name="emailOTP3" onChange={this.checkOTP} onBlur={this.isOTPEmpty} maxLength="1" />
                      <input vlaue={this.state.emailOTP4} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} type="text" className="mr-3 input transition form-control" id="emailOTP4" name="emailOTP4" onChange={this.checkOTP} onBlur={this.isOTPEmpty} maxLength="1" />
                    </div>


                    <div className="mb-3">
                      <p className="text-light d-flex align-items-center w-75 m-auto font-w-300">Waiting for OTP
                        <span className="org font-16 ml-auto otpText">
                          <OtpTimer textColor={"#fa6a50"}
                            buttonColor={"##fa6a50"}
                            background={"#1d1d1d"}
                            minutes={1}
                            seconds={1}
                            text="-"
                            ButtonText="Resend OTP"
                            resend={e => this.otpGenerate}
                          />
                        </span></p>
                    </div>



                    <Button id="click" type="submit" variant="contained" className="w-100 btn openModal btn-lg btn-red" onClick={this.openVerifyOTPSubmit}>
                      Submit</Button>
                  </form>
                </>
              }

              {this.state.openForgotPassword &&
                <>
                  <form onSubmit={this.formReset} id="login_form" className="login_form needs-validation" noValidate>
                    <p className="text-center font-14 resettxt">
                      Just enter the    {
                        this.state.isloginwithmobile === false ? 'email' : 'mobile'} you used to sign up and we will send you a link to reset your password.
                    </p>
                    {
                      this.state.isloginwithmobile === false ? <div className="field icon-wrap form-group">
                        <input name="forgotEmail" type="email" id="forgotEmail" className="input  transition form-control" placeholder="Email Address" required value={this.state.forgotEmail} onChange={this.handelchangeInput} />
                        <div className="invalid-feedback">Enter Email</div>
                        <img src="images/email.svg" alt="Email" width="17" className="icon" />
                      </div> : <div className="field icon-wrap form-group">
                        <input name="forgotMobile" type="tel" pattern="[0-9]{10}" maxlength="10" id="forgotMobile" className="input  transition form-control" placeholder="Mobile Number" required value={this.state.forgotMobile} onChange={this.handelchangeInput} />
                        <div className="invalid-feedback">Enter Mobile Number</div>
                        <img src="images/ph2.png" alt="Email" width="17" className="icon" />
                      </div>}
                    {
                      this.state.isloginwithmobile === false ? <div className='registerwithemial text-y cursor mb-2' onClick={this.changeinp}>Forgot With Mobile Number</div> : <div className='registerwithemial text-y cursor mb-2' onClick={this.changeinp}>Forgot With Email Address</div>
                    }

                    <Button id="click" type="submit" variant="contained" className="w-100 btn openModal btn-lg btn-red" onClick={this.formReset} >
                      Submit</Button>


                    <div className="d-flex mb-3 mt-2">
                      <a className="cursor border-effect font-14 ml-auto link" onClick={this.openLogin}>Back</a>
                    </div>

                  </form>

                </>

              }

              {this.state.openResetPassword &&
                <>
                  <form onSubmit={this.formResetpassword} id="resetPassForm" className="login_form needs-validation" noValidate>
                    <h4 className="text-center text-y mb-3">Reset password</h4>

                    

                    <div className="d-flex align-items-center justify-content-center mt-3 mb-4 otp_box position-relative">
                      <input vlaue={this.state.resetOTP1} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }} type="text" className="mr-3 input transition form-control" id="resetOTP1" name="resetOTP1" onChange={this.checkOTPforgot} onBlur={this.isOTPEmpty} maxLength="1" />
                      <input vlaue={this.state.resetOTP2} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }} type="text" className="mr-3 input transition form-control" id="resetOTP2" name="resetOTP2" onChange={this.checkOTPforgot} onBlur={this.isOTPEmpty} maxLength="1" />
                      <input vlaue={this.state.resetOTP3} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }} type="text" className="mr-3 input transition form-control" id="resetOTP3" name="resetOTP3" onChange={this.checkOTPforgot} onBlur={this.isOTPEmpty} maxLength="1" />
                      <input vlaue={this.state.resetOTP4} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault()}} type="text" className="mr-3 input transition form-control" id="resetOTP4" name="resetOTP4" onChange={this.checkOTPforgot} onBlur={this.isOTPEmpty} maxLength="1" />
                    </div>


                    <div className="mb-3">
                      <p className="text-light d-flex align-items-center w-75 m-auto font-w-300">Waiting for OTP
                        <span className="org font-16 ml-auto otpText">
                          <OtpTimer textColor={"#fa6a50"}
                            buttonColor={"##fa6a50"}
                            background={"#1d1d1d"}
                            minutes={1}
                            seconds={1}
                            text="-"
                            ButtonText="Resend OTP"
                            resend={e => this.formReset}
                          />
                        </span></p>
                    </div>
                    {/* <div className="field icon-wrap form-group">
                      <input name="codereset" type="text" id="codereset" className="input  transition form-control" placeholder="OTP" required value={this.state.codereset} onChange={this.handelchangeInput} />
                      <img src="images/pass.svg" alt="Email" width="17" className="icon" />
                    </div> */}
                    <div className="field icon-wrap form-group">
                      <input name="passwordreset" type="password" id="passwordreset" className="input  transition form-control" placeholder="New password" required value={this.state.passwordreset} onChange={this.handelchangeInput} />
                      <img src="images/pass.svg" alt="Email" width="17" className="icon" />
                      <VisibilityOffIcon className="show_password first  d-none" />
                      <VisibilityIcon className="show_password sec" />
                    </div>

                    <div className="field icon-wrap form-group">
                      <input name="confirmpasswordreset" type="password" id="confirmpasswordreset" className="input  transition form-control" placeholder="Confirm password" required value={this.state.confirmpasswordreset} onChange={this.handelchangeInput} />
                      <img src="images/pass.svg" alt="Email" width="17" className="icon" />
                      <VisibilityOffIcon className="show_password first  d-none" />
                      <VisibilityIcon className="show_password sec" />
                    </div>
                    <Button id="click" type="submit" variant="contained" className="w-100 btn openModal btn-lg btn-red" 
                    onClick={this.formResetpassword}>
                      Reset Password</Button>
                    <div className="d-flex mb-3 mt-2">
                      <a className="cursor border-effect font-14 ml-auto link" onClick={this.openLogin}>Back</a>
                    </div>
                  </form>
                </>
              }
            </div>
          </div>
        </section>

      </>)
  }
}
export default PhoneLogin;
