import React from 'react';
import axios from 'axios';
import { LOGIN, COUNTRYCODE, PARTNER_ID, USER_DETAILS } from '../../url';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import $ from 'jquery'
class FixedPlanBox extends React.Component {
  state = {
    isSubmit: false, waitLogin: false
  }
  formSubmit = (e) => {
    //this.props.changeModal('mobile')
    e.preventDefault();
    this.setState({ isSubmit: true })
    var partnerid = e.target.partnerid.value;
    var userid = e.target.userid.value;
    var order_id = e.target.orderid.value;
    var amount = e.target.amount.value;
    var currency = e.target.currency.value;
    var si_amount = e.target.si_amount.value;
    var plan_days = e.target.plan_days.value;
    var si_frequency = e.target.si_frequency.value;
    var si_start_date = e.target.si_start_date.value;
    var si_frequency_type = e.target.si_frequency_type.value;
    var si_bill_cycle = e.target.si_bill_cycle.value;

    var formData = new FormData();
    formData.append('partnerid', partnerid);
    formData.append('userid', userid);
    formData.append('orderid', order_id);
    formData.append('amount', amount);
    formData.append('currency', currency);
    formData.append('si_amount', si_amount);
    formData.append('plan_days', plan_days);
    formData.append('si_frequency', si_frequency);
    formData.append('si_start_date', si_start_date);
    formData.append('si_frequency_type', si_frequency_type);
    formData.append('si_bill_cycle', si_bill_cycle);
    axios.post('http://otm.planetcast.in/pay', formData, {
    }).then((response) => {
      console.log(response);
      /*if(response.data.status==1)
      {
        localStorage.setItem('userid',this.props.userid);
        this.setState({waitLogin:true})
        setTimeout(()=>this.props.history.push('/'),2000);
      }
      else{
        alert('Error')
      }*/

    }).catch((error) => {
      console.log(error);
    })
  }
  render() {
    if (!this.state.waitLogin) {
      const number = Math.random();
      number.toString(36);
      const orderid = "w" + number.toString(36).substr(2, 9);
      console.log(orderid);
      //const Userid=localStorage.getItem('userid');

      return (<div id="login_form" class="l_signup">
        <div class="popup_l">
          <img src="images/popup_logo.png" alt="" />
        </div>
        <form method="post" action="http://otm.planetcast.in/pay">
          <div class="fields">
            <div class="field field-info">
              <label className="text-center field-info"><h3 className="current-info">Current Plan</h3></label>
              <p className="text-center"><h4>Try For 7 Days</h4></p>
              <p className="text-center price-color"><i className="fa fa-rupee"></i> 1</p>
              <div className="inputbox">
                <input type="hidden" name="partnerid" id="tid" value={PARTNER_ID} />
                <input type="hidden" name="userid" value={this.props.user_id} />
                <input type="hidden" name="orderid" value={orderid} />
                <input type="hidden" name="amount" value="1.00" />
                <input type="hidden" name="currency" value="INR" />
                <input type="hidden" name="si_amount" value="99" />
                <input type="hidden" name="plan_days" value="7" />
                <input type="hidden" name="si_frequency" value="1" />
                <input type="hidden" name="si_start_date" value="09-12-2019" />
                <input type="hidden" name="si_frequency_type" value="YEAR" />
                <input type="hidden" name="si_bill_cycle" value="5" />
                <input type="hidden" name="web" value="web" />
              </div>
            </div>
            <div className="text-center">User Have to Pay Rs 1/- For 7days. After completion of 7days, You have to subscribe for yearly plan which will be an auto-renewal cycle for deduction</div>

          </div>
          {!this.state.isSubmit && <div class="buttons">
            <button type="submit">Pay Now</button>
          </div>}
          {this.state.isSubmit && <div class="buttons">
            <button type="submit" disabled>Loading...</button>
          </div>}


          <div class="clearfix"></div>
        </form></div>)
    }
    else {
      return (<div class="mobnum login_load" id="mobile_number">
        <div class="popup_l">
          <img src="images/popup_logo.png" alt="" />
        </div>
        <Loader
          type="Rings"
          color="#9012cd"
          height={100}
          width={100}
        />
        <p>Signing In ,Please Wait</p>
      </div>)
    }
  }
}
export default FixedPlanBox;
