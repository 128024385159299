import React from 'react';
import { Link } from 'react-router-dom'
const NotFound = () => {
  return (
    <React.Fragment>
      <ul className="bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <section className="login-bg d-flex align-items-center justify-content-center error_page"
        style={{ marginTop: '-63px' }}>
        <div className="login-card text-center">
          <div className="text-center mb-3">
            <img className="login_logo wow fadeInUp" src="images/not-found.png" alt="" />
          </div>

          <h3 className="text-center text-y mt-4  wow fadeInUp" data-wow-delay="0.2s">Opps Page not found !</h3>
          <p className="text-center text-white  wow fadeInUp" data-wow-delay="0.4s">Sorry the page you're looking for was not found</p>

          <Link to="/" className="cursor btn btn-red btn-lg  wow fadeInUp" data-wow-delay="0.6s">Go to Home</Link>

        </div>
      </section>

    </React.Fragment>
  )
}
export default NotFound;
