import React from "react";
import ReactDOM from "react-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Modal from "./modal";
import { toast, cssTransition } from 'react-toastify';
import SocialLogin from '../PhoneLogin/SocialLogin';
import $ from 'jquery';

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 750
});

class Emailpopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      name: "",
      modalInputEmail: ""
    };
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    let email_reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let email = this.state.modalInputEmail
    //alert(email)
    if (email === '') {
      //toast("Please fill email field", { transition: Zoom });
      alert("Please fill email field")
      return false;
    }

    if (!email_reg.test(String(email).toLowerCase())) {
      //toast("Email id is wrong", { transition: Zoom });
      alert("Email id is wrong")
      return false;
    }
    this.setState({ name: email });

    if(email){
      let socialAuthData = JSON.parse(localStorage.getItem("socialAuthData"))
      socialAuthData.email = email
      //console.log(socialAuthData.email)
      if(socialAuthData.email){
        SocialLogin('signup', socialAuthData).then((response) => {
          //console.log(result);
          if (response.data.status === "Success") {
            $('#snackbar .msg').html(response.data.data);
            $('#snackbar').addClass('show').addClass('bg-success').removeClass('bg-danger');
            setTimeout(function () {
              $('#snackbar').removeClass('show');
            }, 3000);
            //this.setState({ isLoading: true });
            localStorage.removeItem('socialAuthData')
            setTimeout(() =>
              this.props.history.push('/')
              //window.location.href = "/"
              , 1000)
          }

        }).catch((error) => {
          /**
          *If the response status code is 409 - Conflict, then we already have
          **/
          if (error.response.status === 409) {
            let msg = "Session time out";
            $('#snackbar .msg').html(msg);
            $('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
            setTimeout(function () {
              $('#snackbar').removeClass('show');
              //window.location.href = "/"
              this.props.history.push('/')
              return false;
            }, 2000);
          } else {
            console.log(error.message);
          }
        });
      }else{
        console.log("Something went wrong in social login")
      }
    }
    //this.modalClose();
  }

  modalOpen() {
    this.setState({ modal: true });
  }

  modalClose() {
    this.setState({
      modalInputEmail: "",
      modal: false
    });
  }

  render() {
    return (
      <div className="App">
        <Modal show={this.state.modal} handleClose={(e) => this.modalClose(e)}>
          {/* <h2>Hello Modal</h2> */}
          <div className="form-group">
            <label>Enter Your Email:  </label>
            <input
              type="email"
              value={this.state.modalInputEmail}
              name="modalInputEmail"
              onChange={(e) => this.handleChange(e)}
              className="inputemailbox" required={true}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-primary" onClick={(e) => this.handleSubmit(e)} type="button">
              Save
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Emailpopup;
