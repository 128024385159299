import React, { useEffect, useState } from 'react';
import $ from 'jquery';

import Header from './component/header/header';
import Footer from './component/footer/footer';
import PAYMENTREDIRECT from './PaymentRedirect';
import { BrowserRouter, Router, Link, Route, Switch, HashRouter, Redirect } from "react-router-dom";
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import routes from './routes.js';
import axios from 'axios';
import { COUNTRYCODE, PARTNER_ID, USERID, MENU_DATA } from './url';
import Authenticator from './container/Authentication/Authentication'

import useCustomHooks from '../src/container/CustomHooks/CustomHooks.js';

import LoaderCircle from '../src/component/loader/LoaderCircle';

const App = (props) => {

  const [keyword, setkeyword] = useState("");
  const [leftMenu, setleftMenu] = useState([]);
  const [user_name_val, setuser_name_val] = useState("");
  const [modalShow, setmodalShow] = useState(false);


  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      getData();
    }
  }, []);

  const getData = async () => {
    const token = { headers: { token: USERID } }
    var formData = new FormData();
    // formData.append('userid', USERID);
    // formData.append('countrycode', COUNTRYCODE);
    formData.append('partnerid', PARTNER_ID);
    formData.append('menu_type', 'l');
    let response = await axios.post(MENU_DATA, formData, { headers: { 'token': localStorage.getItem('token') } })

    if (response.data.status === 'Success') {
      setleftMenu(response.data.data)
    }
    else {

    }
  }


  $('header').removeClass('res-header');
  $('header').removeClass('dark_');
  $('footer').show();

  $('body,html').animate({
    scrollTop: 0
  }, 10);

  $('header .part1').removeClass('col-md-2').addClass('col-md-1');
  $('header .part2').removeClass('col-md-7').addClass('col-md-8');
  $('header').removeClass('scroll').removeClass('dark');
  return (
   
      <div >
        <LoaderCircle />
        <ToastContainer autoClose={2000} />
        <Header {...props} leftMenu={leftMenu} />
        <div style={{ minHeight: '100vh' }} className="body_wrapper">
          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                <route.component {...props} />
              )} />)
                : (null);
            },
            )}
          </Switch>
        </div>
        <Footer />
      </div>

  );
}
export default Authenticator(App);
