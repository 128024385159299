import { COUNTRYCODE,SOCIALLOGIN,GET_TOKEN,UUID } from '../../url'
import axios from 'axios';
import $ from 'jquery';
export default function SocialLogin(type, userData) {
	if(userData.email){
        return new Promise(async(resolve, reject) => {
			let checkLogin = SOCIALLOGIN;
			let gettoken = GET_TOKEN;
			
			if(userData.provider === "google"){
			  var authId = 3; 
			}else{
			  var authId = 2; 
			}
	
			const resdata = await fetch('https://ipapi.co/json/', { 
				method: 'GET',
			}).then((response) =>
				response.json()
			)
	
			await axios({
				method: 'GET',
				url: gettoken,
				data: null,
			}).then(function (response) {
				localStorage.setItem('token', response.data.token);
			})
			
			var bodyFormData = new FormData();
			bodyFormData.append('authuid', userData.authId);
			bodyFormData.append('email', userData.email?userData.email:'');  
			//bodyFormData.append('authprovider', userData.provider);  
			bodyFormData.append('authprovider', authId);  
			bodyFormData.append('country',resdata.country);
			bodyFormData.append('uuid', UUID);
			bodyFormData.append('type', 'web');
	
			await axios({
				method: 'POST',
				url: checkLogin,
				data: bodyFormData,
				headers: {
				  'token' : localStorage.getItem('token'),
				},
			})
			.then(function (response) {
				// localStorage.setItem("authId",authId)
				resolve(response);
				if (response.status !== 200) {
					
				}
				
			})
			.catch(function (error) {
				console.log( 'sociallogin',error);
				let msg = "Alredy user exist";
					$('#snackbar .msg').html(msg);
					$('#snackbar').addClass('show').addClass('bg-danger').removeClass('bg-success');
					setTimeout(function () {
					  $('#snackbar').removeClass('show');
					  window.location.href = "/"
					}, 3000);
			});
		});	
	}else{
		alert('Email is not verified on facebook or gmail')
		return false
	}	
}