import Fingerprint from "fingerprintjs";
// const base_url = "https://mercytv-a.planetcast.in"; //live
const base_url = "http://stg-api.planetcast.in:8089";  //staging
//export const PAYU_URL = "https://test.payu.in/_payment";
export const PAYU_URL = "https://secure.payu.in/_payment";
export const DOMURL = 'https://mercy.video'
export const COUNTRYCODE = localStorage.getItem('countrycode')?localStorage.getItem('countrycode'):'IN'
export const PARTNER_ID = 'ott886'
export const HLSJSVERSION = '1.0.11'
export const DOMCOOKIE = '.mercy.video'
export const OTPSIGNIN = base_url + '/otp-login';
export const HOME_DATA = base_url + '/home'
export const EXPLORE = base_url + '/explore'
export const UPCOMING = base_url + '/upcoming'
export const ONDEMAND_DATA = base_url + '/category_subdata'
export const MENU_DATA = base_url + '/menu'
export const VIDEO_DATA = base_url + '/video-info'
export const CATEGORY_DATA = base_url + '/category-data'
export const VIEW_MORE_CATEGORY_DATA = base_url + '/category_info'
export const SERIES_SUB_DATA = base_url + '/series-sub-data'
export const RELATED_VIDEO = base_url + '/related-video'
export const SEARCH = base_url + '/search'
export const CONTINUE_WATCHING = base_url + '/continue-watching'
export const FAVOURITES = base_url + '/favourite'
export const WATCHLATER = base_url + '/watch-later';
export const USER_HISTORY = base_url + '/user-history'
export const VIEW_MORE = base_url + '/category-data'
export const LIVE_FEED = base_url + '/live-feed'
export const SUPPORT = base_url + '/support'
export const PLANLIST = base_url + '/plans-detail'
export const INITIATEORDER = base_url + '/v1/response';
export const PAYMENTAPI = base_url + '/payu-hash-key';
export const PAYMENT_INFO = base_url + '/pay_info';
export const COUPONS = base_url + '/coupons';
export const CHUNK_UPLOAD = base_url + '/v1/chunk_upload';
export const MYACCOUNT = base_url + '/account';
export const PINCODE = base_url + '/pin';
export const AGENT = base_url + '/agent';
// export const LOGIN = base_url + '/application-login';
export const LOGIN = base_url + '/v1/application-login';
export const SOCIALLOGIN = base_url + '/social-login';
export const REGISTER = base_url + '/signup';
export const REGION = base_url + '/region';
export const LOGOUT = base_url + '/logout';
export const USER_DETAILS = base_url + '/login';
export const USER_PROFILE = base_url + '/user-profile'
export const UPLOAD_PROFILE = base_url + '/upload'
export const GET_TOKEN = base_url + '/gettoken';
export const PLAYLIST_VIEW = base_url + '/playlistview'
export const PLAYLIST = base_url + '/playlist'
export const PLAYLIST_METADATA = base_url + '/playlistmetadata';
export const GENERATE_TICKET = base_url + '/ticket';
export const TRANSCATION = base_url + '/transaction-history';
export const TICKET_HISTORY = base_url + '/ticket-history';
export const QURAN_PDF = base_url + '/pdf-download';
export const ORDERSTATUS = base_url + '/orderstatus';
export const SI_CANCEL = base_url + '/si_cancel';
export const ADVERTISE = base_url + '/advertise';
export const LIKE = base_url + '/like'
export const VERIFYCODE = base_url + '/verify_code'
export const PAYMENT_TOKEN = base_url + '/payment_token';
export const PAYMENT_RESPONSE = base_url + '/payu-payment-status';
export const USERID = localStorage.getItem('userid') == null ? 0 : localStorage.getItem('userid')
var fingerprint = new Fingerprint().get();
getCookie("uuid")?getCookie("uuid"):setCookie("uuid",fingerprint,"86400");
var getuuid_cookie = getCookie("uuid");
localStorage.setItem('uuid', getuuid_cookie)?localStorage.setItem('uuid', getuuid_cookie):localStorage.setItem('uuid', getCookie("uuid"))
export const UUID = getuuid_cookie
//export const UUID = fingerprint
export const OS = window.navigator.platform
export const NAME = window.navigator.appCodeName
// export const FORGOT_PASSWORD = base_url + '/forgot-password';
export const FORGOTPASSWORDV1 = base_url + '/v1/forgot-password';
export const RESETPASSWORD = base_url + '/v1/reset-password';
export const FORGOT_PASSWORD = base_url + '/forgot-password';
export const RESET_PASSWORD = base_url + '/reset-password';
export const CHANGE_PASSWORD = base_url + '/change-password';
// export const GETPLAN_API = base_url + '/plan_details'
// export const PAYMENTINTENT_API = base_url + '/web_client_secret'
export const FACEBOOKAPP_ID = "2000951993414032";
export const GOOGLECLIENT_ID = "953739807397-tn1vg1j3ruqbocmnjbasf5mn26qjassn.apps.googleusercontent.com"
//export const PAYUMERCHANT_KEY = "v1XYF5"
//export const PAYU_RESPONSE = window.location.origin+'/#/payment-response';
//export const PAYU_RESPONSE = 'http://localhost/request.php';
export const PAYU_RESPONSE = 'https://clients.planetcast.in/mercypaybiz.php';

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cookieName) {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [key,value] = el.split('=');
    cookie[key.trim()] = value;
  })
  return cookie[cookieName];
}